import React from 'react'
import { Link } from 'react-router-dom'
import { useAppContext } from '../context/appcontext'
import SpinnerComponent from './SpinnerComponent'
import { withRouter } from 'react-router'


const PublicCollectionPanel = () => {
    const {collection, user} = useAppContext()

    if (!collection || !user) {
        return <SpinnerComponent />
    }

    return (<>
        <div className="w-full flex justify-center my-2 bg-gray-700 p-3">
            {collection.isPublic ? (
                <p>Collection is shared. | <Link to="/toggle-public-collection">Manage sharing</Link></p>
            ) : (
                <p>Collection is <b>not</b> shared. <Link to="/toggle-public-collection">Manage sharing</Link></p>
            )}    
            
        </div>
    </>)
}

export default withRouter(PublicCollectionPanel)