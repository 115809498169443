import { useMutation } from '@apollo/react-hooks'
import React from 'react'
import { useAppContext } from '../context/appcontext'
import { ADD_FOR_SALE_COLLECTION } from '../graphql/mutations'
import { GET_COLLECTIONS_QUERY } from '../graphql/queries'
import { useHistory } from 'react-router'


const AddForSaleCollection = () => {

    const {dispatch} = useAppContext()
    const history = useHistory()

    const [executeAddForSaleCollectionMutation, {loading}] = useMutation(ADD_FOR_SALE_COLLECTION, {

        onCompleted: (result) => {
            let forSaleCollection = result.collections.createForSaleCollection
            dispatch({
                type: "setCollection",
                payload: {
                    collection: forSaleCollection,
                    collectionId: forSaleCollection?.id,
                }
            }) 
            history.go(0)           
        },
        refetchQueries: [
            {
                query: GET_COLLECTIONS_QUERY
            }
        ],        
        });

    const handleClick = () => {
        executeAddForSaleCollectionMutation()
    }

    return (<>
        <div className="container">
            <div className="row" style={{marginBottom: "200px", marginTop: "100px"}}>
                <div className="w-full">
                    <h1>Hooooray!</h1>
                    <p>You can now add a "for sale" collection where you can organize your figures for sale.</p>
                    <button onClick={handleClick} className="my-5 btn-blue w-full">{loading ? "Creating ..." : "Create a for sale collection"}</button>
                </div>
            </div>
        </div>
    </>)
}

export default AddForSaleCollection