import React from 'react'
import { CheckBoxContainer, CheckBoxGroup } from '../styles'

const FigureOptions = ({defaultValues, typeChange, yearData}) => {
    return (<>
        <div className="w-full">             
            <CheckBoxGroup className="">
                <CheckBoxContainer className="">
                    <input className="mr-2" defaultChecked={yearData ? yearData.cardedFilter : defaultValues.carded} type="checkbox" onChange={typeChange} id="carded" name="carded" value="carded"/>
                    <label className="" htmlFor="carded">Carded figures</label>
                </CheckBoxContainer>
                <CheckBoxContainer className="">
                    <input className="mr-2" defaultChecked={yearData ? yearData.vehicleFilter : defaultValues.vehicle} type="checkbox" onChange={typeChange} id="vehicle" name="vehicle" value="vehicle" />
                    <label className="" htmlFor="vehicle">Vehicle drivers</label>
                </CheckBoxContainer>
                <CheckBoxContainer className="">
                    <input className="mr-2" defaultChecked={yearData ? yearData.otherFilter : defaultValues.other} type="checkbox" onChange={typeChange} id="other" name="other" value="other"/>
                    <label className="" htmlFor="other">Other</label>
                </CheckBoxContainer>
            </CheckBoxGroup>
        </div>
    </>)

}

export default FigureOptions