import React from 'react'


const ErrorComponent = () => {
    return (
        <div className="top-0 left-0 right-0 bottom-0 w-full h-screen z-50 flex flex-col items-center justify-center">

            <h1>Oh no!</h1>
            <img src="img/GI_PSA.jpg" alt="error" />
            <br/>
            <p>Something went wrong. Try again or contact us if the problem persists.</p>
        </div>
    )
}

export default ErrorComponent