import React, {useState} from 'react';
import { useQuery } from '@apollo/react-hooks';
import OWNEDFIGURE_QUERY from '../graphql/ownedfigurequery'
import SpinnerComponent from '../Components/SpinnerComponent'
import { withRouter } from "react-router";
import ListMyCollection from '../Components/MyCollection/ListMyCollection';
import AddFigure from '../Components/MyCollection/AddFigure'
import ErrorComponent from '../Components/ErrorComponent'
import ScrollToTop from '../Components/ScrollToTop';
import { useAppContext } from '../context/appcontext';
import CATALOGFIGURE_QUERY from '../graphql/catalogfigurequery';



const MyCollection = (props) => {
    const {collectionId, collection} = useAppContext();
    const [addMode, setAddMode] = useState(false)



    const isLoggedIn = localStorage.getItem("token") !== null

    if (!isLoggedIn) {
        props.history.push("/login")
    }


    const { loading, error, data } = useQuery(OWNEDFIGURE_QUERY, 
        {
            variables: {
                collectionId: collectionId
            },
            skip: collectionId === null
        });

    const { loading: catalogloading, error: catalogerror, data: catalogdata } = useQuery(CATALOGFIGURE_QUERY);   

    if (loading) return <SpinnerComponent LoadingText="Loading your figures..."/>;
    if (loading || catalogloading) return <SpinnerComponent LoadingText="Loading your collection..."/>;
    if (error || catalogerror) return <ErrorComponent/>;

    const addButtonClicked = () => {
        setAddMode(true)        
    }

    const onClose = () => {
        setAddMode(false)
    }

    if (!collection) return <SpinnerComponent LoadingText="Loading your collection..."/>;

    let {forSale, allowDuplicates} = collection

    return (
        <React.Fragment>
            <ScrollToTop />
                {
                    !addMode 
                    ?
                        <>
                            <ListMyCollection allFigures={catalogdata.catalogFigures} forSale={forSale} allowDuplicates={allowDuplicates} data={data} addButtonClicked={addButtonClicked}/>
                        </>
                    :   
                        <>
                            <AddFigure onClose={onClose}/>
                        </>
                        
                }
                
        </React.Fragment>

    )
};

export default withRouter(MyCollection)