import React from 'react';
import { Route, Switch } from 'react-router-dom';
import MyCollection from './Pages/MyCollection';
import LoginPage from './Pages/LoginPage';
import MissingFigures from './Pages/MissingFigures';
import MissingAccessory from './Pages/MissingAccessory';
import ShowCatalogFigure from './Pages/ShowCatalogFigure';
import EditFigurePage from './Pages/EditFigurePage';
import UserPage from './Pages/UserPage'
import PasswordPage from './Pages/PasswordPage'
import Logout from './Components/User/Logout'
import RegisterPage from './Pages/RegisterPage'
import ForgotPasswordPage from './Pages/ForgotPasswordPage'
import ResetPasswordPage from './Pages/ResetPasswordPage'
import ErrorComponent from './Components/ErrorComponent';
import AddFigurePage from './Pages/AddFigurePage'
import {theme} from './theme'
import { ThemeProvider } from "styled-components";
import {GlobalStyle} from './globalstyle'
import ForSalePage from './Pages/ForSalePage';
import MyCollectionRoute from './Routes/MyCollectionRoute';
import ForSaleRoute from './Routes/ForSaleRoute';
import './styles/output.css'
import FrontPage from './Pages/FrontPage';
import TogglePublicCollectionPage from './Pages/TogglePublicCollectionPage';
import PublicCollectionPage from './Pages/PublicCollectionPage';
import DefaultRoute from './Routes/DefaultRoute';
import PublicCollectionRoute from './Routes/PublicCollectionRoute';
import PublicMissingAccessoryPage from './Pages/PublicMissingAccessoryPage';
import PublicMissingFiguresPage from './Pages/PublicMissingFiguresPage';
import PublicShowFigurePage from './Pages/PublicShowFigurePage';
import FileCardsPage from './Pages/FileCards/FileCardsPage';
import AddFileCardPage from './Pages/FileCards/AddFileCardPage';
import EditFileCardPage from './Pages/FileCards/EditFileCardPage';
import FigureFileCardsPage from './Pages/EditFigure/FigureFileCardsPage';



function App() {

 return (
    <ThemeProvider  theme={theme}>
      <GlobalStyle/>
          <Switch>
            <DefaultRoute exact path="/login" component={LoginPage} />
            <Route exact path="/" component={FrontPage} />
            <MyCollectionRoute exact path="/my-collection/add/:id" component={AddFigurePage} />        
            <MyCollectionRoute exact path="/my-collection/:id" component={EditFigurePage} />       
            <MyCollectionRoute exact path="/my-collection/:id/filecards" component={FigureFileCardsPage} />       

            <MyCollectionRoute exact path="/my-collection" component={MyCollection} />
            <MyCollectionRoute exact path="/missing" component={MissingFigures} />
            <MyCollectionRoute exact path="/missing-accessories" component={MissingAccessory} />
            <DefaultRoute exact path="/catalog/figure/:year/:slug" component={ShowCatalogFigure} />
            <DefaultRoute exact path="/user/password" component={PasswordPage} />
            <DefaultRoute exact path="/user" component={UserPage} />
            <DefaultRoute exact path="/register" component={RegisterPage} />
            <DefaultRoute exact path="/logout" component={Logout} />
            <DefaultRoute exact path="/forgot-password" component={ForgotPasswordPage} />
            <DefaultRoute exact path="/reset-password" component={ResetPasswordPage} />
            <ForSaleRoute exact path="/for-sale/add/:id" component={AddFigurePage} />        
            <ForSaleRoute exact path="/for-sale/:id" component={EditFigurePage} />       
            <ForSaleRoute exact path="/for-sale" component={ForSalePage} />
            <DefaultRoute exact path="/toggle-public-collection" component={TogglePublicCollectionPage} />
            <PublicCollectionRoute exact path="/public/:alias/:type" component={PublicCollectionPage} />       
            <PublicCollectionRoute exact path="/public/:alias/:type/missing" component={PublicMissingFiguresPage} />                   

            <PublicCollectionRoute exact path="/public/:alias/:type/missing-accessories" component={PublicMissingAccessoryPage} />                   
            <PublicCollectionRoute exact path="/public/:alias/:type/:id" component={PublicShowFigurePage} />
            <MyCollectionRoute exact path="/filecards" component={FileCardsPage} />
            <MyCollectionRoute exact path="/filecards/add" component={AddFileCardPage} />
            <MyCollectionRoute exact path="/filecards/add/:id/:ownedfigureid" component={AddFileCardPage} />
            <MyCollectionRoute exact path="/filecards/:id" component={EditFileCardPage} />


            <DefaultRoute component={ErrorComponent} />
          </Switch>
    </ThemeProvider>
  );
}




export default App;
