import React from 'react';
import { withRouter } from "react-router";
import { LoginOptionsContainer } from '../styles/login.styled';
import {Link} from 'react-router-dom'
import GetApp from '../Components/GetApp';



const RegisterPage = (props) => {


    return (
        <>
            <div className="container" style={{marginTop: "60px", marginBottom: "60px"}}>
                <GetApp />
                <LoginOptionsContainer className="col-md-12">
                    <p>Already have an account? Log in&nbsp;<Link to="/login">here</Link></p>
                </LoginOptionsContainer>
            </div>

        </>
    )
};

export default withRouter(RegisterPage)