import React, {useState} from 'react';
import { useMutation } from '@apollo/react-hooks';

import {RESET_PASSWORD} from '../../graphql/mutations';

import { withRouter } from 'react-router-dom';

import swal from 'sweetalert'

const ForgotPasswordForm = (props) => {
    const initialState = {
        email: props.email,
        password: "",
        confirmpassword: "",
        token: props.token
    }

    const [formState, setFormState] = useState(initialState)
    const [errors, setErrors] = useState([])

    const [doResetPassword, { loading }] = useMutation(RESET_PASSWORD, {
        onCompleted() {
            swal(
                {
                    title: "Password successfuly updated!",
                    button: "Ok"
                }
            )
            props.history.push("/login")
            setFormState(initialState)
          },
          onError: ({ graphQLErrors }) => {
            graphQLErrors.forEach(x => {
                setErrors([...errors, [x.message.replace("GraphQL.ExecutionError: ", "")]])
            })
        }
        });


    const handleChange = (event) => {
        let state = formState
        state[event.target.name] = event.target.value       
        setFormState(state)
    }

    const submit = (event) => {
        event.preventDefault()
        setErrors([])

        if (formState.password === "" || formState.confirmpassword ==="") {
            setErrors([["You must fill out all fields"]])
            return
        }


        if (formState.password !== formState.confirmpassword) {
          
            setErrors([["Password and confirm password do not match"]])
            return
        }


        let vars = {
            request: {
                email: formState.email,
                password: formState.password,
                token: formState.token
            }
        }

       
        doResetPassword({
            variables: vars
        })
    }




    return (
        <div className="container">
            <form>

                    <div className="w-full flex flex-col items-center mt-20">
                            <label htmlFor="lastname">New password</label>
                            <input type="password" onChange={handleChange} defaultValue={formState.password} name="password" className="w-full lg:w-96 input-base" id="password" placeholder="New password"  />
                    </div>
                    <div className="w-full flex flex-col items-center mt-5">
                            <label htmlFor="lastname">Confirm new password (repeat)</label>
                            <input type="password" onChange={handleChange} defaultValue={formState.confirmpassword} name="confirmpassword" className="w-full lg:w-96 input-base" id="confirmpassword" placeholder="Confirm password"  />
                    </div>


                    {                        
                        errors.length > 0
                        ? (
                            <div className="w-full">
                                <h4>Could not reset password:</h4>
                                <ul>
                                    {
                                        errors.map(error => (
                                            <li>{error}</li>
                                        ))
                                    }
                                </ul>
                            </div>
                        )
                        : (
                            <React.Fragment></React.Fragment>
                        )
                    }

                    <div className="w-full flex flex-col items-center mt-5 mb-20">
                            <button onClick={submit} className="btn-blue mt-5 w-full lg:w-96" type="submit">{loading ? "Processing ..." : "Set new password"}</button>
                    </div>



            </form>

        </div>
    )
}

export default withRouter(ForgotPasswordForm)
