import React from 'react'

export const NavigationPublic = () => {



    return (
        <>
                <nav className="py-6 font-semibold uppercase z-50 flex-row flex-nowrap justify-center items-center bg-gray-700 px-4">
                    <section className="p-4 block pb-0">
                        <div className="container">

                            <div className="flex justify-items-start items-center w-full">
                                <div className="">
                                    <a className="font-montserrat font-bold text-3xl inline-block mr-4 text-white flex-grow" href={process.env.REACT_APP_WWW_URL}>GIJOE.IO</a>
                                </div>
                                <div className="lg:flex px-4 invisible hidden lg:visible flex-grow lg:text-xs xl:text-base">
                                    <ul className="font-montserrat font-bold mt-0 flex flex-row ml-auto pl-0 mb-0 list-none uppercase flex-grow ">
                                    { /*page === "collection" && (
                                            <>
                                                <li className="pt-2 mx-1">
                                                    <Link className={`text-white p-4 rounded-lg block no-underline cursor-pointer list-none uppercase hover:no-underline ${page === "for-sale" || page === "collection"} ? "bg-gray-400" : null}`} to={`/${route}/${alias}/${page}`}>Collection</Link>
                                                </li> 
                                                <li className="pt-2 mx-1">
                                                    <Link className={`text-white p-4 rounded-lg block no-underline cursor-pointer list-none uppercase hover:no-underline ${pathname.startsWith("/missing/") ? "bg-gray-400" : null}`} to={`/${route}/${alias}/${page}/missing`}>Missing figures</Link>
                                                </li>                            

                                                <li className="pt-2 mx-1">
                                                    <Link className={`text-white p-4 rounded-lg block no-underline cursor-pointer list-none uppercase hover:no-underline ${pathname.startsWith("/missing-accessories") ? "bg-gray-400" : null}`} to={`/${route}/${alias}/${page}/missing-accessories`}>Missing accessory</Link>
                                                </li>                            
                                            </>
                                    )*/}                         

                                    </ul>
                                </div>
                                <div className="flex self-end">           
                                    <a className="text-xs lg:text-base" href="https://app.gijoe.io">Create your own collection!</a>
                                </div>

                                {/*page === "collection" && (
                                <>
                                    <Burger open={open} setOpen={setOpen} />
                                    <StyledMenu open={open}>
                                        <Link className="font-montserrat" to={`/${route}/${alias}/${page}`} onClick={() => setOpen(false)}>My collection</Link>
                                        <Link className="font-montserrat" onClick={() => setOpen(false)} to={`/${route}/${alias}/${page}/missing`} >Missing figures</Link>
                                        <Link className="font-montserrat" onClick={() => setOpen(false)} to={`/${route}/${alias}/${page}/missing-accessories`}>Missing accessories</Link>
                                    </StyledMenu>
                                    </>
                                )*/}
                            </div>
                    
                        </div>
                    </section>

                </nav>
    
        </>
    )
}

export default NavigationPublic