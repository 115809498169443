import React from 'react'
import { InternalLink } from '../styles/buttons'

const DisplayChange = ({selectText, selectVisual}) => {
    return (
        <div className="flex flex-wrap mb-4 w-full justify-center sm:justify-start">
            <p><InternalLink onClick={selectVisual}>Visual</InternalLink> | <InternalLink onClick={selectText}>Text for copy/paste</InternalLink></p>
        </div>

    )
}

export default DisplayChange