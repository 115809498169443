import styled from 'styled-components'

export const HeaderSection = styled.div`
    padding: 90px 0px;
    background-color: transparent;
    background-image: url('/img/se.jpg');
    background-size: cover;
`

export const LeftContainer = styled.div`
    display: flex;
    padding-left: 0px;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
`

export const LeftTextContainer = styled.div`
    display: block;
    width: 500px;
    height: 400px;
    margin-left: -400px;
    padding-top: 50px;
    padding-right: 50px;
    padding-left: 50px;
    -webkit-box-align: center;
    align-items: center;
    background-color: #d87b7b;    
`

export const LeftText = styled.div`
align-items: center;
font-size: 72px;
line-height: 86px;
font-weight: 700;
text-align: left;
justify-content: center;
    -webkit-box-align: center;
    flex-direction: row;
    -webkit-box-pack: center;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
`

export const RightContainer = styled.div`
    display: flex;
    margin-top: -200px;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
`

export const RightTextContainer = styled.div`
    display: block;
    width: 500px;
    height: 400px;
    margin-left: 598px;
    padding: 73px 50px 100px;
    -webkit-box-align: center;
    align-items: center;
    background-color: #7487a8;
`

export const RightText = styled.div`
align-items: center;
    font-size: 72px;
    line-height: 86px;
    font-weight: 700;
    text-align: left;
    justify-content: center;
    -webkit-box-align: center;
    flex-direction: row;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
`

export const ButtonContainer = styled.div`
    position: static;
    display: flex;
    padding-top: 47px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;    
`

export const IntroSection = styled.div`
    padding-top: 80px;
    padding-bottom: 80px;
`

export const IntroText = styled.div`
    padding-left: 10px;
    padding-right: 10px;
`

export const IntroImage = styled.div`
    padding-left: 10px;
    padding-right: 10px;
`

export const USPSection = styled.div`
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: #d87b7b;
` 

export const USPContainer = styled.div`
    padding-top: 30px;
    padding-right: 20px;
    padding-left: 20px;
`

export const USPListContainer = styled.div`
    display: flex;
    padding: 20px;
    float: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    align-items: center;
    border: 1px none #000;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 1px 1px 0 0 #000;
`

export const USPList = styled.ul`
    color: #7487a8;
    padding-left: 0;
    list-style: none;
    margin-top: 0px;
    margin-bottom: 10px;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    display: block;
`

export const USPListItem = styled.li`
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 26px;
    background-image: url(/img/long-check-mark.png);
    background-position: 0% 50%;
    background-size: 15px;
    background-repeat: no-repeat;
`

export const USPText = styled.div`
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 20px;
`

export const BlogSection = styled.div`
    padding-top: 50px;
    padding-bottom: 50px;
`

export const BlogImageContainer = styled.div`
    padding: 20px;
`

export const BlogTextContainer = styled.div`
    padding: 20px;
`

export const BlogHeading = styled.h1`
    margin-bottom: 30px;
    font-size: 70px;
`


