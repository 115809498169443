import React from 'react'

const GetApp = () => {
    return (
        <div className="container">
            <h1>Get the app and start managing!</h1>
            <p className="text-gray-200">Download the app and get access to the web tool.</p>
            <div className="flex items-center justify-center py-10 flex-col md:flex-row">
            <a href="https://play.google.com/store/apps/details?id=io.gijoe.app" target='_blank' rel='noopener noreferrer'>
                <img alt="Download from Google Play" className="mx-5" width="200" src="/img/google_play.png" />
            </a>
            <a href="https://apps.apple.com/us/app/my-g-i-joes/id1606553734" target='_blank' rel='noopener noreferrer'>
                <img alt="Download from app store" className="mx-5" width="150" src="/img/appstore.svg" />
            </a> 
            </div>
        </div>
    )
}

export default GetApp