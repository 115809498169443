
import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { FigureCard, FigureCaption, FigureOverlay, FigureContainer } from '../../styles';
import { YearFilter } from '../FigureFilter';
import FigureOptions from '../FigureOptions';
import DisplayChange from '../DisplayChange';

const ListMissingFigures = (props) => {

    const years = ["1982", "1983", "1984", "1985", "1986", "1987", "1988", "1989", "1990", "1991", "1992", "1993", "1994"]

    const GET_YEAR_FILTER = gql`
    {
        yearFilter @client
        cardedFilter @client  
        vehicleFilter @client  
        otherFilter @client  
        missingMode @client
        currentCollection @client
    }
    `;


    const { data: yearData, client } = useQuery(GET_YEAR_FILTER);


    const yearChange = (event) => {
        client.writeData({ data: { yearFilter: event.target.value } })
    }

    const typeChange = (event) => {
        
        if (event.target.value === "carded") {
            client.writeData({ data: { cardedFilter: !yearData.cardedFilter } })  
        }

        if (event.target.value === "vehicle") {
            client.writeData({ data: { vehicleFilter: !yearData.vehicleFilter } })  
        }

        if (event.target.value === "other") {
            client.writeData({ data: { otherFilter: !yearData.otherFilter } })  
        }
    }


    let filter = props.missingFigures
    if (yearData.yearFilter !== "none") {
        filter = filter.filter(x => x.year.toString() === yearData.yearFilter)
    }

    if (yearData.cardedFilter !== true)
    {
        filter = filter.filter(x => x.tags.length !== 0)

        //filter = filter.filter(x => x.tags.length === 0)
    }

    if (yearData.vehicleFilter !== true)
    {
        filter = filter.filter(x => x.tags.some(y => y !== "Vehicle driver")  || x.tags.length === 0)


        //filter = filter.filter(x => x.tags.length === 0)
    }
    if (yearData.otherFilter !== true)
    {
        filter = filter.filter(x => x.tags.some(y => y === "Vehicle driver") || x.tags.length === 0)

        //filter = filter.filter(x => x.tags.length === 0)
    }

    const modeChange = (mode) => {
        client.writeData({ data: { missingMode: mode } })
    }
    

    
    

    return (
            <div className="container">

                <div className="mx-auto w-full my-5">
                    <h1>Missing figures</h1>
                </div>

                <div className="w-full flex flex-row py-2 sm:py-0">
                    <YearFilter onYearChange={yearChange} yearData={yearData} years={years} />
                </div>

                <FigureOptions yearData={yearData} typeChange={typeChange} />

                <DisplayChange selectText={() => modeChange("text")} selectVisual={() => modeChange("visual")}/>

                    <div className="flex flex-wrap -mx-4 mb-4">
                    {
                        filter
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .sort((a, b) => a.tags.length - b.tags.length)
                            .sort((a, b) => {
                                if (a.tags[0] !== undefined && b.tags[0] !== undefined) {
                                    const aTag = a.tags[0].toString()
                                    const bTag = b.tags[0].toString()
                                    if (aTag > bTag) return 1
                                    if (bTag > aTag) return -1                                
                                }
                                return 0
                            })
                            .map(({ id, name, imageUrlCrop, version, year, slug, tags }) => (
                                yearData.missingMode === "visual" ? (
                                    <FigureContainer className="xl:w-2/12 md:w-1/3 lg:w-1/4 sm:w-1/2 w-full px-4 mb-6 flex justify-center sm:justify-start flex-col items-center" key={id}>
                                        <FigureCard>
                                            <Link to={"/catalog/figure/" + year + "/" + slug}>
                                                <img className="w-max h-auto align-middle border-none box-border cursor-pointer" src={process.env.REACT_APP_CMS_URL + imageUrlCrop} alt=""/>
                                            </Link>

                                            {
                                                tags.length > 0 ?
                                                (
                                                    <FigureOverlay type="tag">{tags[0]}</FigureOverlay>
                                                )
                                                : (<></>)
                                            }
                                        </FigureCard>

                                        <FigureCaption>
                                                <h6 style={{marginBottom: '0px'}}>{name} v{version} ({year})</h6>
                                        </FigureCaption>
                                        
                                    </FigureContainer>
                                    )
                                    : (
                                        <><div className="w-full px-4 text-sm md:text-base">{name} v{version} ({year})</div></>
                                    )
                            ))
                    }
                   </div>
            </div>
    )
}

export default ListMissingFigures