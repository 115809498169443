import React from 'react'
import { Route } from 'react-router';
import Layout from '../Components/Common/Layout';

const DefaultRoute = ({ children, ...rest }) => {
    return (
        <Layout>
          <Route {...rest} />
        </Layout>

    );
  }
  
export default DefaultRoute