import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import OWNEDFIGURE_QUERY from '../graphql/ownedfigurequery'
import SpinnerComponent from '../Components/SpinnerComponent'
import CONDITIONS_QUERY from '../graphql/conditionquery';
import EditFigure from '../Components/MyCollection/EditFigure'
import ErrorComponent from '../Components/ErrorComponent'
import ScrollToTop from '../Components/ScrollToTop';
import { useAppContext } from '../context/appcontext';
import { GET_OWNEDFIGURE_QUERY } from '../graphql/queries';

const EditFigurePage = (props) => {

    const isLoggedIn = localStorage.getItem("token") !== null

    if (!isLoggedIn) {
        props.history.push("/login")
    }


    const {collection} = useAppContext();

    const { loading, error, data } = useQuery(GET_OWNEDFIGURE_QUERY, 
        {
            variables: {
                id: props.match.params.id
            }
        });

    const { loading: conditionloading, data: conditiondata } = useQuery(CONDITIONS_QUERY);  
 
    if (loading || !collection) return <SpinnerComponent LoadingText="Loading your collection..."/>;
    if (conditionloading) return <SpinnerComponent LoadingText="Loading..."/>;

    if (error) return <ErrorComponent />;

    var figure = data.ownedFigure

    if (figure === undefined)
    {
        return (
            <>
                <div>Kunne ikke finne figur</div>
            </>
        )
    }


    return (
        <>
            <ScrollToTop />
            <EditFigure collection={collection} figure={figure} conditions={conditiondata} />
        </>
    )
}

export default EditFigurePage
