import React, {useState} from 'react';
import { useMutation } from '@apollo/react-hooks';

import {UPDATE_PASSWORD} from '../../graphql/mutations';

import { withRouter } from 'react-router-dom';
import UserNavigation from './UserNavigation'
import { Button } from '../../styles';
import swal from 'sweetalert'

const UserForm = (props) => {

    const initialState = {
        currentpassword: "",
        newpassword: "",
        confirmpassword: ""
    }

    const [formState, setFormState] = useState(initialState)
    const [errors, setErrors] = useState([])

    const [doUpdatePassord, { loading }] = useMutation(UPDATE_PASSWORD, {
        onCompleted() {
            swal(
                {
                    title: "Password updated!",
                    button: "success"
                }
            )
            setFormState(initialState)
          },
          onError: ({ graphQLErrors }) => {
            graphQLErrors.forEach(x => {
                setErrors([...errors, [x.message.replace("GraphQL.ExecutionError: ", "")]])
            })
        }
        });


    const handleChange = (event) => {
        let state = formState
        state[event.target.name] = event.target.value       
        setFormState(state)
    }

    const submit = (event) => {
        event.preventDefault()
        setErrors([])


        if (formState.newpassword !== formState.confirmpassword) {
          
            setErrors([["Password and confirm password do not match"]])
            return
        }

        let password = {
            currentPassword: formState.currentpassword,
            newPassword: formState.newpassword
        }
        doUpdatePassord({
            variables: {
                password: password
            }
        })
    }




    return (
        <div className="container">
            <UserNavigation />
            <form>
                <div className="m-5">
                    <div className="w-full py-2">
                        <div className="form-group">
                            <label htmlFor="currentpassword">Current password</label>
                            <input type="password" onChange={handleChange} defaultValue={formState.currentpassword} name="currentpassword" className="input-base w-full py-2" id="currentpassword" placeholder="Current password" />
                        </div>
                    </div>
                    <div className="w-full py-2">
                        <div className="form-group">
                            <label htmlFor="lastname">New password</label>
                            <input type="password" onChange={handleChange} defaultValue={formState.newpassword} name="newpassword" className="input-base w-full py-2" id="newpassword" placeholder="New password"  />
                        </div>
                    </div>
                    <div className="w-full py-2">
                        <div className="form-group">
                            <label htmlFor="lastname">Confirm new password (repeat)</label>
                            <input type="password" onChange={handleChange} defaultValue={formState.confirmpassword} name="confirmpassword" className="input-base w-full py-2" id="confirmpassword" placeholder="Confirm password"  />
                        </div>
                    </div>




                    {                        
                        errors.length > 0
                        ? (
                            <div className="col-md-12">
                                <h4>Cannot update password:</h4>
                                <ul>
                                    {
                                        errors.map(error => (
                                            <li>{error}</li>
                                        ))
                                    }
                                </ul>
                            </div>
                        )
                        : (
                            <React.Fragment></React.Fragment>
                        )
                    }

                    <div className="col-md-12 update-save-button">
                        <div className="form-group">
                            <Button onClick={submit} className="mt-5 w-full btn-blue" type="submit">{loading ? "Processing ..." : "Update password"}</Button>
                        </div>
                    </div>

                </div>
            </form>

        </div>
    )
}

export default withRouter(UserForm)
