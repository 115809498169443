import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import PageHeader from '../Components/PageHeader';
import SubNavigation from '../Components/SubNavigation';
import SpinnerComponent from '../Components/SpinnerComponent'
import ErrorComponent from '../Components/ErrorComponent'

import { withRouter } from "react-router";
import {USER_QUERY} from '../graphql/queries'
import UserForm from '../Components/User/UserForm'
import ScrollToTop from '../Components/ScrollToTop';


const UserPage = (props) => {

    const isLoggedIn = localStorage.getItem("token") !== null

    if (!isLoggedIn) {
        props.history.push("/login")
    }

    const { loading, error, data } = useQuery(USER_QUERY);

    if (loading) return <SpinnerComponent LoadingText="Loading your profile..."/>;
    if (error) return <ErrorComponent />;

    return (
        <>
            <ScrollToTop />

            <PageHeader title="Min bruker" />
            <SubNavigation  />    
            <UserForm {...data} />
        </>
    )
};

export default withRouter(UserPage)