import React from 'react'

const MissingAlias = ({onInputChange, setAlias}) => {
    return (
        <>
            <h2 className="mt-5">Alias not set</h2>
            <p className="text-gray-400">You have not yet set your alias. Your alias can be whatever you want, like your first name or a nickname. The alias must be unique and will be a part of the URL to your collection.</p>
            <div className="w-full mt-5">
                <label htmlFor="alias">Your alias</label>
                <input onChange={onInputChange} className="mt-2 input-base w-full" type="text" name="alias"/>
            </div>
            <button onClick={setAlias} className="mt-5 btn-blue w-full">Set your alias</button>
        </>
    )

}

export default MissingAlias