import React from 'react';
import { withRouter } from "react-router";
import ResetPasswordForm from '../Components/User/ResetPasswordForm'


const ResetPasswordPage = (props) => {
    
    const params = new URLSearchParams(props.location.search)
    const email = params.get('email')
    const token = params.get('code')

    return (
        <>
            <ResetPasswordForm email={email} token={token} />        
        </>
    )
};

export default withRouter(ResetPasswordPage)