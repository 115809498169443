import React from 'react'
import { StyledMenu } from '../../styles'
import {Link, withRouter} from 'react-router-dom'
import { useApolloClient } from '@apollo/react-hooks'

const Menu = ({open, setOpen, history}) => {

    const client = useApolloClient()

    const logout = () => {
        localStorage.clear("token")
        client.resetStore()
        history.push('/login')
    }


    return (
        <StyledMenu open={open}>
            <Link className="font-montserrat" to="/my-collection" onClick={() => setOpen(false)}>My collection</Link>
            <Link className="font-montserrat" onClick={() => setOpen(false)} to="/missing" >Missing figures</Link>
            <Link className="font-montserrat" onClick={() => setOpen(false)} to="/missing-accessories">Missing accessories</Link>
            <Link className="font-montserrat" onClick={() => setOpen(false)} to="/for-sale">For sale</Link>            
            <Link className="font-montserrat" onClick={() => setOpen(false)} to="/filecards">Filecards</Link>               
            <Link className="font-montserrat" onClick={() => setOpen(false)} to="/user">Account settings</Link>
            <Link className="font-montserrat" onClick={() => {setOpen(false); logout()}} to="#">Log out</Link>

        </StyledMenu>

    )
}

export default withRouter(Menu)