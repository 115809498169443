import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { FigureContainer, FigureCard, FigureCaption } from '../../styles';
import ScrollToTop from '../../Components/ScrollToTop';
import gql from "graphql-tag";
import DisplayChange from '../../Components/DisplayChange';





const ListMissingAccessoryPublic = ({alias, figures}) => {

    const GET_FILTER = gql`
    {
        missingMode @client
    }
    `;

    const { data: yearData, client } = useQuery(GET_FILTER);



    const missingFigures = figures.filter(x => x.missingaccessory.length > 0)

    const modeChange = (mode) => {
        client.writeData({ data: { missingMode: mode } })
    }

    


    return (
        <>
            <ScrollToTop />

            <div className="container">

                    <div className="w-full my-5">
                        <h1>Missing accessory for {alias}</h1>
                    </div>


                    <DisplayChange selectText={() => modeChange("text")} selectVisual={() => modeChange("visual")}/>


                <div className="flex flex-wrap -mx-4 mb-4">
                    {
                        missingFigures.length === 0
                        ?
                        <div>
                            <div className="w-full">There are no figures with missing equipment</div>
                        </div>
                        :
                        missingFigures.sort((a, b) => a.figure.name.localeCompare(b.figure.name)).map(({ id, figure, missingaccessory }) => (
                            yearData.missingMode === "visual" ? (

                            <FigureContainer key={id} className="xl:w-2/12 md:w-1/3 lg:w-1/4 sm:w-1/2 w-full px-4 mb-6 flex justify-center sm:justify-start flex-col items-center">
                                <FigureCard>
                                        <img className="img-fluid" src={process.env.REACT_APP_CMS_URL + figure.imageUrlCrop} alt="" />
                                </FigureCard>



                                <FigureCaption>
                                    <h6 className="font-semibold text-lg" style={{marginBottom: '0px'}}>{figure.name} v{figure.version} ({figure.year})</h6>
                                </FigureCaption>
                                <div className="mx-auto">
                                {
                                    missingaccessory.map(({ name }) => (
                                        <p className="text-sm text-gray-400" style={{marginBottom: "2px"}} key={name}>Missing: {name}</p>
                                    ))
                                }
                                </div>

                            </FigureContainer>
                            )
                            : (

                                <div className="w-full px-4 text-sm md:text-base">                                    
                                    {figure.name} v{figure.version} ({figure.year}):&nbsp;
                                    {
                                    missingaccessory.map(({name}, i, {length}) => (
                                        <>{name}{length - 1 !== i ? (<>,&nbsp;</>) : null}</>
                                    ))
                                }
                                </div>
                            )

                        ))

                    }

                </div>
            </div>
        </>


    )
}

export default ListMissingAccessoryPublic