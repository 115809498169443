import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import SpinnerComponent from '../../Components/SpinnerComponent'
import { withRouter } from "react-router";
import ErrorComponent from '../../Components/ErrorComponent'
import ScrollToTop from '../../Components/ScrollToTop';
import { useAppContext } from '../../context/appcontext';
import { ALL_FILECARDS_QUERY } from '../../graphql/queries';
import ListFileCards from '../../Components/FileCards/list/ListFileCards';

const FileCardsPage = (props) => {
    const {collection, collectionId} = useAppContext()

    const isLoggedIn = localStorage.getItem("token") !== null

    if (!isLoggedIn) {
        props.history.push("/login")
    }

    const { loading: fileCardsLoading, 
            error: fileCardsError, 
            data: fileCardsData } = useQuery(ALL_FILECARDS_QUERY, 
                {
                    variables: {
                        collectionId: collectionId
                    },
                    skip: collection === null
                }
    );


    if (fileCardsLoading || collection === null) return <SpinnerComponent LoadingText="Loading your filecards..."/>;
    if (fileCardsError) return <ErrorComponent />


    

    return (<>
        <ScrollToTop />
        <ListFileCards fileCards={fileCardsData.fileCards} />
    </>)
}

export default withRouter(FileCardsPage)
