import { gql } from 'apollo-boost';

export const GET_OWNEDFIGURE_QUERY = gql`
    query($id: UUID!) {
        ownedFigure(id: $id) {
            id           
            condition {id, name}
            figure {id, name, imageUrlRaw, imageUrlCrop, version, year, slug, tags, type}
            missingaccessory {id, name}
            ownedaccessory {id, name}
            note
            price
            joints
            oring
            paint
            screw
            brokenParts
            elbowCracks
            
            images {
                id
                location
            }

            fileCards {
                id
                figure {
                    id
                    name
                }
                condition {
                    id
                    name
                }
                fileCardImages {
                    id
                    location
                }
                notes
            }
        }

    } 
`;


export const GET_FILECARD_QUERY = gql`
    query($id: UUID!) {
        fileCard(id: $id) {
            id
            figure {
                id
                name
            }
            condition {
                id
                name
            }
            fileCardImages {
                id
                location
            }
            notes
        }
    }
    
`

export const ALL_FILECARDS_QUERY = gql`
    query($collectionId: UUID!) {
        fileCards(collectionId: $collectionId) {
            id
            figure {
                id
                name
            }
            condition {
                id
                name
            }
            fileCardImages {
                id
                location
            }
        }
    }
`

export const USER_QUERY = gql`
{
    user
    {
      firstName
      email
      lastName
      username
      acceptNewsletter
      alias
    }
}`

export const GET_COLLECTIONS_QUERY = gql`
query {
    collections {
        myCollections {
            id
            name
            forSale
            allowDuplicates
            isPublic
            validYears {
                sharedYears { 
                    year
                    sharedAccessory
                    sharedCollection
                    sharedMissing
                }
                hasValidYears
                hasValidMissingYears
        }
        }
    }
}
`

export const GET_PUBLIC_COLLECTION = gql`
    query($alias: String!, $type: String!) {
        publicCollections {
            collection(alias: $alias, type: $type) {
                figures {
                    id
                    condition {id, name}
                    figure {id, name, imageUrlRaw, imageUrlCrop, version, year, slug, tags, type}
                    missingaccessory {id, name}
                    ownedaccessory {id, name}
                    note
                    price
                    joints
                    oring
                    paint
                    brokenParts
                    images {
                        id
                        location
                    }
                }
                forSale
                missingFigures {
                    id
                    name
                    imageUrlRaw
                    imageUrlCrop
                    version
                    year
                    slug
                    tags
                }
                validYears {
                    sharedYears { 
                        year
                        sharedAccessory
                        sharedCollection
                        sharedMissing
                    }
                    hasValidYears
                    hasValidMissingYears
                }
            }
        }
    }
`