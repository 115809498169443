import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import {USER_QUERY} from '../../graphql/queries';
import { useQuery } from '@apollo/react-hooks';
import { useAppContext } from '../../context/appcontext';







const Logout = (props) => {

    const { client } = useQuery(USER_QUERY);
    const {dispatch} = useAppContext()


    const logout = () => {
        localStorage.clear("token")
        client.clearStore()
        dispatch({
            type: 'setToken',
            payload: null
        })
        props.history.push('/login')
    }
    
    return (
        <Link><img onClick={logout} alt="logout button" width="20" src="/img/exit.png" /></Link>
    )
};

export default withRouter(Logout)