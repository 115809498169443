import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import {FigureContainer, FigureOverlay, FigureCard, FigureCaption} from '../../styles'
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import AddForSaleCollection from '../AddForSaleCollection';
import { CompleteFilter, YearFilter } from '../FigureFilter';
import FigureOptions from '../FigureOptions';
import PublicCollectionPanel from '../PublicCollectionPanel';






const ListMyCollection = (props) => {

    const {forSale, hasForSaleCollection} = props
    
    const [shareVisible, setShareVisible] = useState(false)

    const [completeFilter, setCompleteFilter] = useState("none")
    const [missingHover, setMissingHover] = useState(null)



    const GET_YEAR_FILTER = gql`
    {
        yearFilter @client
        cardedFilter @client  
        vehicleFilter @client  
        otherFilter @client 
        currentCollection @client 
    }
    `;

    const { data: yearData, client } = useQuery(GET_YEAR_FILTER);

    const yearChange = (event) => {
        client.writeData({ data: { yearFilter: event.target.value } })
    }


    if (forSale && !hasForSaleCollection) {
        return (
            <AddForSaleCollection />
        )
    }


    // Filter owned figures
    let filterData = props.data.ownedFigures

    if (completeFilter === "complete") {
        filterData = filterData.filter(x => x.missingaccessory.length === 0)
    }            
    if (completeFilter === "incomplete") {
        filterData = filterData.filter(x => x.missingaccessory.length > 0)
    }

    if (yearData?.yearFilter !== "none") {
        filterData = filterData.filter(x => x.figure.year.toString() === yearData?.yearFilter)
    }

    if (yearData?.vehicleFilter !== true) filterData = filterData.filter(x => x.figure.tags.some(y => y !== "Vehicle driver")  || x.figure.tags.length === 0)
    if (yearData?.otherFilter !== true) filterData = filterData.filter(x => x.figure.tags.some(y => y === "Vehicle driver") || x.figure.tags.length === 0)
    if (yearData?.cardedFilter !== true) filterData = filterData.filter(x => x.figure.tags.length !== 0)

    let missingFigures = props.allowDuplicates  ? props.allFigures : props.data.missingFigures

    if (yearData?.vehicleFilter !== true) missingFigures = missingFigures.filter(x => x.tags.some(y => y !== "Vehicle driver")  || x.tags.length === 0)
    if (yearData?.otherFilter !== true) missingFigures = missingFigures.filter(x => x.tags.some(y => y === "Vehicle driver") || x.tags.length === 0)
    if (yearData?.cardedFilter !== true) missingFigures = missingFigures.filter(x => x.tags.length !== 0)


    
    const years = ["1982", "1983", "1984", "1985", "1986", "1987", "1988", "1989", "1990", "1991", "1992", "1993", "1994"]

    
    const completeChange = (event) => {
        setCompleteFilter(event.target.value)
    }

    const typeChange = (event) => {
        
        if (event.target.value === "carded") {
            client.writeData({ data: { cardedFilter: !yearData.cardedFilter } })  
        }

        if (event.target.value === "vehicle") {
            client.writeData({ data: { vehicleFilter: !yearData.vehicleFilter } })  
        }

        if (event.target.value === "other") {
            client.writeData({ data: { otherFilter: !yearData.otherFilter } })  
        }
    }

    console.log(filterData)
  
    return (
        <div className="container">
                <div className="w-full mt-5 md:my-5">
                    <h1>{forSale ? "For sale" : "Main collection"}</h1>
                </div>

                <div className="w-full flex justify-end my-1 text-sm">
                    <p className="cursor-pointer" onClick={() => setShareVisible(!shareVisible)}>Share collection</p>
                </div>

                {shareVisible && (
                    <PublicCollectionPanel />
                )}


                <div className="w-full flex flex-row py-2 sm:py-0">
                    <div className="w-full md:w-1/2 py-2 sm:py-0 mr-4">
                        <YearFilter onYearChange={yearChange} yearData={yearData} years={years} />
                    </div>
                    <div className="w-full md:w-1/2 py-2 sm:py-0">
                        <CompleteFilter onCompleteChange={completeChange} />
                    </div>    
                </div>
                <FigureOptions yearData={yearData} typeChange={typeChange} />

  
                {yearData.yearFilter === "none" && (
                    <div className="flex flex-wrap mb-4">
                        <div className="w-full text-gray-400">Select a year to add figures to your collection</div>
                    </div>
                )}


    {filterData.length === 0 && (
        <div className="flex flex-wrap mb-4">
            <div className="w-full text-gray-400">
                {yearData?.yearFilter !== "none" 
                    ? <p>You have no figures from {yearData?.yearFilter} in your collection that matches the filter</p>
                    : <p>You have no figures in your collection. Select a year and start adding!</p>
                }
            </div>
        </div>
    )}

    <div className="flex flex-wrap -mx-4 mb-4">
        {
            filterData.sort((a, b) => a.figure.name.localeCompare(b.figure.name)).map(({ id, figure, condition, missingaccessory, price, images }) => (
                <FigureContainer  key={id} className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-2/12 px-4 mb-6 flex justify-center sm:justify-start">
                    <Link to={props.forSale ? "/for-sale/" + id : "/my-collection/" + id}>
                        <FigureCard className="cursor-pointer">
                            <img className="w-max h-auto align-middle border-none box-border cursor-pointer" 
                            src={images.length > 0 ? `https://gijoe-io.imgix.net/${images[0].location}?h=400&w=350&fit=crop` : `${process.env.REACT_APP_CMS_URL}${figure.imageUrlCrop}`} 
                            alt="figure"
                        />
                            <FigureOverlay type={missingaccessory?.length === 0 ? "complete" : "incomplete"}>
                                {missingaccessory?.length === 0 ? "Complete!" : "Missing accessory (" + missingaccessory.length +")"}
                            </FigureOverlay>
                        </FigureCard>
                        <FigureCaption className="pt-2">
                            <h6 className="font-semibold">{figure.name} v{figure.version} ({figure.year})</h6>
                            <p>Condition: {condition.name}</p>
                            {props.forSale && (
                                <p style={{marginBottom: '0px'}}>Price: {price}</p>
                            )}
                        </FigureCaption>
                    </Link>
                </FigureContainer>    
            ))
        }
    </div>

    {yearData?.yearFilter !== "none" &&(
            <div className="w-full" style={{marginBottom: "10px"}}>
                <h2 className="font-montserrat font-bold text-2xl mb-5 ">{forSale ? "Add figures for sale" : "Add figures to your collection"}</h2>
            </div>

        )}


    <div className="flex flex-wrap -mx-4 mb-4">

        {
            missingFigures
            .filter(x => x.year === parseInt(yearData.yearFilter))
            .sort((a, b) => a.name.localeCompare(b.name))
            .sort((a, b) => a.tags.length - b.tags.length)
            .sort((a, b) => {
                if (a.tags[0] !== undefined && b.tags[0] !== undefined) {
                    const aTag = a.tags[0].toString()
                    const bTag = b.tags[0].toString()
                    if (aTag > bTag) return 1
                    if (bTag > aTag) return -1                                
                }
                return 0
            })
            .map(({ id, imageUrlCrop, name, version, year, slug, tags }, idx) => (         
       
                <FigureContainer onMouseOver={() => setMissingHover(idx)} onMouseOut={() => setMissingHover(null)} key={idx} className="xl:w-2/12 md:w-1/3 lg:w-1/4 sm:w-1/2 w-full px-4 mb-6 flex justify-center sm:justify-start flex-col items-center">
                    <Link to={props.forSale ? '/for-sale/add/' + id : "/my-collection/add/" + id}>
                        <FigureCard hover={missingHover === idx}>
                            <img style={{opacity: missingHover === idx ? "1" : "0.2"}} className="w-max h-auto align-middle border-none box-border cursor-pointer" src={process.env.REACT_APP_CMS_URL + imageUrlCrop} alt="figure"/>
                            {
                                tags.length > 0 ?
                                (
                                    <FigureOverlay type="tag">
                                        {tags[0]}
                                    </FigureOverlay>
                                )
                                : (<></>)
                            }
                        </FigureCard>
                    </Link>

                    <Link to={"/catalog/figure/" + year + "/" + slug}>
                        <FigureCaption className="pt-2">
                            <h6 className="font-semibold">{name} v{version} ({year})</h6>
                        </FigureCaption>
                    </Link>
                </FigureContainer>
            ))
        }
    </div>

</div>        
    )
    

}

export default withRouter(ListMyCollection)
