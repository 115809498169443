import React from 'react';
import { useQuery  } from '@apollo/react-hooks';
import OWNEDFIGURE_QUERY from '../graphql/ownedfigurequery'
import SpinnerComponent from '../Components/SpinnerComponent'
import CATALOGFIGURE_QUERY from '../graphql/catalogfigurequery'
import CONDITIONS_QUERY from '../graphql/conditionquery';
import ErrorComponent from '../Components/ErrorComponent'
import AddFigure from '../Components/MyCollection/AddFigure'
import ScrollToTop from '../Components/ScrollToTop';
import PageHeader from '../Components/PageHeader'
import SubNavigation from '../Components/SubNavigation'
import { useAppContext } from '../context/appcontext';





const AddFigurePage = (props) => {

    const isLoggedIn = localStorage.getItem("token") !== null

    if (!isLoggedIn) {
        props.history.push("/login")
    }

    const {collection, collectionId} = useAppContext();

    const { loading, error, data } = useQuery(OWNEDFIGURE_QUERY, 
        {
            variables: {
                collectionId: collectionId
            }
        });

    const { loading: catalogloading, error: catalogerror, data: catalogdata } = useQuery(CATALOGFIGURE_QUERY);   
    const { loading: conditionloading, error: conditionerror, data: conditiondata } = useQuery(CONDITIONS_QUERY);   


    if (loading) return <SpinnerComponent LoadingText="Loading your figures..."/>;
    if (catalogloading) return <SpinnerComponent LoadingText="Loading catalog..."/>;
    if (conditionloading) return <SpinnerComponent LoadingText="Loading..."/>;

    if (error || catalogerror || conditionerror) return <ErrorComponent />;

    if (!collection) return <SpinnerComponent LoadingText="Loading catalog..."/>;

    var ids = data.ownedFigures.map((x => x.figure.id))
    var missingFigures = catalogdata.catalogFigures.filter(x => !ids.includes(x.id))   


    return (
        <React.Fragment>
            <ScrollToTop />
            <PageHeader title="Legg til figur" />
            <SubNavigation selected="myCollection" />    

            <AddFigure 
                conditions={conditiondata} 
                missingFigures={missingFigures} 
                addedFigureId={props.match.params.id}
                allFigures={catalogdata.catalogFigures}
                collection={collection}
                
            />
        </React.Fragment>
    )
}

export default AddFigurePage