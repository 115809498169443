import React from 'react'
import { StyledBurger } from "../../styles"



const Burger = ({ open, setOpen }) => {
    return (
      <StyledBurger className="lg:invisible" open={open} onClick={() => setOpen(!open)}>
        <div />
        <div />
        <div />
      </StyledBurger>
    )
}

export default Burger