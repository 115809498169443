import styled from 'styled-components'
import { Link } from 'react-router-dom';

export const LinkButton = styled(Link)`
    border-color: ${props => props.theme.borderColor};
    color: ${props => props.theme.textColor};

    &:hover {
        background-color: ${({ theme }) => theme.primaryHover} !important;
        color: black;
        border-color: ${props => props.theme.borderColor};

      }


  
`
export const Button = styled.button`
    border-color: ${props => props.theme.borderColor};
    color: ${props => props.theme.textColor};
    height: 4rem;

    &:hover {
        background-color: ${({ theme }) => theme.primaryHover} !important;
        color: black;
        border-color: ${props => props.theme.borderColor};

      }
`

export const UploadImageButton = styled.input`

    &:before{
      content: 'Upload image';
      border-color: ${props => props.theme.borderColor};
      color: ${props => props.theme.textColor};
      height: 4rem;
    }

    &:hover {
        background-color: ${({ theme }) => theme.primaryHover} !important;
        color: black;
        border-color: ${props => props.theme.borderColor};

      }
`

export const InternalLink = styled.span`
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`
