import React from 'react'
import { Link } from 'react-router-dom'
import AddFileCardButton from './AddFileCardButton'
import FileCardItem from './FileCardItem'

const ListFileCards = ({fileCards, figureId = null, ownedFigureId = null}) => {
    return (
        <div className="pb-5">
            <div className="py-5 flex">
                <div className="w-2/3">
                    <h1>Filecards</h1>
                </div>            
                <div className="w-1/3">
                    <AddFileCardButton figureId={figureId} ownedFigureId={ownedFigureId} />
                </div>
            </div>

            <div className="flex flex-wrap">
                {fileCards.map(fileCard => (
                        <FileCardItem key={fileCard.id} fileCard={fileCard} figureId={figureId} ownedFigureId={ownedFigureId}/>
                ))}
            </div>
        </div>
    )
}

export default ListFileCards