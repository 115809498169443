import React from 'react'
import { Link } from 'react-router-dom'

const EditFigureMenu = ({figureId}) => {
    return (
        <div className="flex justify-center">
            <div className="px-5">
                <Link to={`/my-collection/${figureId}`}>Figure</Link>
            </div>
            <div className="px-5">
                <Link to={`/my-collection/${figureId}/filecards`}>Filecards</Link>
            </div>
        </div>        
    )
}

export default EditFigureMenu