import { useQuery } from '@apollo/react-hooks'
import React from 'react'
import CONDITIONS_QUERY from '../../graphql/conditionquery'
import ErrorComponent from '../ErrorComponent'
import SpinnerComponent from '../SpinnerComponent'

const SelectCondition = ({onChange, defaultValue = null}) => {
    const {data, loading, error} = useQuery(CONDITIONS_QUERY)
    
    
    if (loading) return <SpinnerComponent>Loading ...</SpinnerComponent>
    if (error) return <ErrorComponent />

    return (<>
        <div className="w-full mb-2">
            <label htmlFor="condition">General condition</label>
            <select name="condition" onChange={onChange} defaultValue={defaultValue != null ? defaultValue : "none"} className="input-base w-full">
                <option value="none" disabled>Choose condition</option>
                {
                    data.conditions.map(({id, name}) => (
                    <option key={id} value={id}>{name}</option>
                    ))
                }
            </select>
        </div>

    </>
    )
}

export default SelectCondition