import { gql } from 'apollo-boost';



const CATALOGFIGURE_QUERY = gql`
{
    catalogFigures {
        id
        name
        imageUrlRaw
        imageUrlCrop
        version 
        year
        slug
        accessory {id, name}
        tags
        type
    }
}`;

export default CATALOGFIGURE_QUERY