import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import CATALOGFIGURE_QUERY from '../graphql/catalogfigurequery'
import SpinnerComponent from '../Components/SpinnerComponent'
import ErrorComponent from '../Components/ErrorComponent'
import ScrollToTop from '../Components/ScrollToTop';
import PageHeader from '../Components/PageHeader';
import SubNavigation from '../Components/SubNavigation';
import OWNEDFIGURE_QUERY from '../graphql/ownedfigurequery'
import {LinkButton} from '../styles'
import { useAppContext } from '../context/appcontext';


const ShowCatalogFigure = (props) => {

    const isLoggedIn = localStorage.getItem("token") !== null

    if (!isLoggedIn) {
        props.history.push("/login")
    }


    const { loading, error, data } = useQuery(CATALOGFIGURE_QUERY);

    const {collectionId} = useAppContext();


    const { loading: ownedLoading, error: ownedError, data: ownedData } = useQuery(OWNEDFIGURE_QUERY, 
        {
            variables: {
                collectionId: collectionId
            }
        });


    if (loading) return <SpinnerComponent LoadingText="Loading catalog figures ..."/>;
    if (ownedLoading) return <SpinnerComponent LoadingText="Loading your collection..."/>;
    if (error || ownedError) return <ErrorComponent />;

    let figure = data.catalogFigures.filter(x => x.slug === props.match.params.slug && x.year.toString() === props.match.params.year)[0]
    let figureOwned = ownedData.ownedFigures.filter(x => x.figure.id === figure.id).length > 0

    
    return (
        <div>
            <ScrollToTop/>
            <PageHeader title="Figur" />
            <SubNavigation />


            <div className="container">
                <div className="my-8">
                    <h1 className="text-2xl text-center text-uppercase mt-20">{figure.name} v{figure.version} ({figure.year})</h1>
                    <h5 className="text-center">{figure.tags.length > 0 ? figure.tags[0] : "Carded figure"}</h5>
                </div>

                <div className="flex-wrap flex flex-row content-between my-16">
                    <div className="w-full sm:w-1/2">
                        <img alt="figure" className="img-fluid  mx-auto " src={process.env.REACT_APP_CMS_URL + figure.imageUrlRaw + "?width=400"} />
                    </div>
                    <div className="w-full sm:w-1/2 text-center mx-auto mt-5 sm:mt-0">
                        <h4 className="text-xl font-semibold">{figure.accessory.length > 0 ? "Accessories" : "Does not have accessories"}</h4>
                        <br />
                        {
                            figure.accessory.map(({ name }, id) => (
                                <p key={id} className="m-1">{name}</p>
                            ))
                        }

                    </div>
                </div>
                
            </div>

                {!figureOwned && (
                            <div className="w-full sm:w-3/4 md:w-1/2 mx-auto my-5">
                                <LinkButton to={"/my-collection/add/" + figure.id} className="block text-center w-full mt-5 bg-blue-500 font-bold text-white px-4 py-3 transition duration-300 ease-in-out hover:bg-blue-600 mr-6">Add to collection</LinkButton>
                            </div>
                        )}

    
        </div>                

    )}

export default ShowCatalogFigure