import React from 'react'
import { Button } from '../styles';

export const FileUploadButton = ({handleFile, children}) => {

    const hiddenFileInput = React.useRef(null);

    const handleImageButtonClick = event => {
        hiddenFileInput.current.click();
    }

    return (<>

        <div className="form-group">
                <Button onClick={handleImageButtonClick} style={{flexGrow: true, marginTop: "20px"}} className="btn btn-outline-primary btn-block">{children}</Button>
                <input type="file"
                    accept="image/*"
                    ref={hiddenFileInput}
                    onChange={handleFile}
                    style={{display:'none'}} 
                />
        </div>

    
    </>)
}

export const FileUploadImage = ({handleFile}) => {
    const hiddenImageFileInput = React.useRef(null);

    const handleImageButtonClick = event => {
        hiddenImageFileInput.current.click();
    }
    
    return (<>
                <div className="bg-gray-400 mx-1 mt-1 mb-2">
                    <img 
                        alt="add new"
                        src="https://static.thenounproject.com/png/187803-200.png" 
                        height="100"
                        width="100"
                        onClick={handleImageButtonClick}
                        style={{cursor: 'pointer'}}
                    />   
                </div>   

                <input type="file"
                    accept="image/*"
                    ref={hiddenImageFileInput}
                    onChange={handleFile}
                    style={{display:'none'}} 
                />
    </>)
}