import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${props => props.theme.backgroundColor};
  }

  a {
    color: ${props => props.theme.linkColor};
    text-decoration: none;
    background-color: transparent;
  }  

`;
