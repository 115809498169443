import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter as Router} from 'react-router-dom'
import { ApolloProvider } from '@apollo/react-hooks';
import ApolloClient from 'apollo-boost';
import { AppContextProvider } from './context/appcontext';



const client = new ApolloClient({
    uri: process.env.REACT_APP_GQL_URL,
    request: (operation) => {
        const token = localStorage.getItem('token')
        operation.setContext({
          headers: {
            authorization: token ? `Bearer ${token}` : ''
          }
        })
      },
      clientState: {
        defaults: {
          yearFilter: 'none',
          cardedFilter: true,
          vehicleFilter: true,
          otherFilter: true,
          missingMode: 'visual',
          currentCollection: null
        },
        typeDefs: `
          type Query {
            yearFilter: String
            missingMode: String
            cardedFilter: Boolean
            vehicleFilter: Boolean
            otherFilter: Boolean
            currentCollection: String
          }
        `,
      },  
      onError: ({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
        {
          let error = graphQLErrors[0].message.replace("GraphQL.ExecutionError: ", "")
          if (error === "The current user is not authorized to access this resource.")
          {
            localStorage.clear("token")          
          }
  
        }
      }


  });
  

ReactDOM.render(
    <Router onUpdate={() => window.scrollTo(0, 0)}>
        <ApolloProvider client={client}>
          <AppContextProvider>
                <App />

          </AppContextProvider>
        </ApolloProvider>
    </Router>,

    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
