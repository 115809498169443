import React from 'react'
import Select from 'react-select'


const FigureSearchComponent = ({figures, onChange, defaultValue}) => {    
    
    const options = figures.map(x => { 
        return {
            value: x.id,
            label: `${x.name} v${x.version} (${x.year})`
        }})
    const selectedOption = options.filter(x => x.value === defaultValue)[0]
    console.log(selectedOption)
    return (
        <Select 
            options={options} 
            className="text-black w-full"
            autoFocus={true}
            onChange={onChange}
            //defaultValue={{label: defaultValue.name, value: defaultValue.id}}
            defaultValue={selectedOption}
            
        />
        )
}

export default FigureSearchComponent