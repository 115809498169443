import React from 'react'
import { Route } from 'react-router';
import LayoutPublic from '../Components/Common/LayoutPublic';

const PublicCollectionRoute = ({ children, ...rest }) => {

    return (
        <LayoutPublic>
            <Route {...rest} />
        </LayoutPublic>

    );
  }
  export default PublicCollectionRoute