import React from 'react';
import PageHeader from '../Components/PageHeader';
import { withRouter } from "react-router";
import ForgotPasswordForm from '../Components/User/ForgotPasswordForm'


const ForgotPasswordPage = (props) => {

    return (
        <React.Fragment>
            <PageHeader title="Glemt passord" />
            <ForgotPasswordForm  />
        </React.Fragment>
    )
};

export default withRouter(ForgotPasswordPage)