import { useMutation } from '@apollo/react-hooks'
import React from 'react'
import { useAppContext } from '../../../context/appcontext'
import { REMOVE_FILECARD_MUTATION } from '../../../graphql/mutations'
import { ALL_FILECARDS_QUERY, GET_OWNEDFIGURE_QUERY } from '../../../graphql/queries'
import deleteIcon from '../../../images/delete-icon.png'
import SpinnerComponent from '../../SpinnerComponent'
import swal from 'sweetalert'

const DeleteFileCardButton = ({fileCard, ownedFigureId = null}) => {

    const {collectionId} = useAppContext()

    const refetchQueries = [
        {
            query: ALL_FILECARDS_QUERY,
            variables: {
                collectionId: collectionId
            }   
        }
    ]

    if (ownedFigureId !== null) {
        refetchQueries.push({
            query: GET_OWNEDFIGURE_QUERY,
            variables: {
                id: ownedFigureId
            }
        })
    }


    const [executeRemoveFileCard] = useMutation(REMOVE_FILECARD_MUTATION, {
        onCompleted: () => {

        },
        refetchQueries

    })

    const onDeleteClick = (id) => {
        swal({
            title: "Remove filecard?",
            text: "Are you sure you want to remove this filecard?",
            buttons: true,
            dangermode: true
        }).then((value)  => {
            if (value === true) {            
                executeRemoveFileCard({
                    variables: {
                        id
                    }
                })
            }
        })           
    }

    if (collectionId === null) return <SpinnerComponent>Loading ...</SpinnerComponent>


    return (
        <img 
        src={deleteIcon} 
        className="absolute w-10 self-end pr-4 pt-2 cursor-pointer" 
        onClick={() => onDeleteClick(fileCard.id)}
        />        
    )
}

export default DeleteFileCardButton