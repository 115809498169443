import React, {useState} from 'react';
import { useMutation } from '@apollo/react-hooks';

import {REQUEST_PASSWORD_RESET} from '../../graphql/mutations';

import { withRouter } from 'react-router-dom';

import swal from 'sweetalert'

const ForgotPasswordForm = (props) => {
    const initialState = {
        email: "",
    }

    const [formState, setFormState] = useState(initialState)
    const [errors, setErrors] = useState([])

    const [doRequestPasswordReset, { loading }] = useMutation(REQUEST_PASSWORD_RESET, {
        onCompleted() {
            swal(
                {
                    title: "An email has been sent with instructions on how to reset your password!",
                    button: "Ok"
                }
            )
            props.history.push("/login")
            setFormState(initialState)
          },
          onError: ({ graphQLErrors }) => {
            graphQLErrors.forEach(x => {
                setErrors([...errors, [x.message.replace("GraphQL.ExecutionError: ", "")]])
            })
        }
        });


    const handleChange = (event) => {
        let state = formState
        state[event.target.name] = event.target.value       
        setFormState(state)
    }

    const submit = (event) => {
        event.preventDefault()
        setErrors([])

       
        doRequestPasswordReset({
            variables: {
                email: formState.email
            }
        })
    }




    return (
        <div className="container">
            <form>
                <div className="m-20 flex flex-col items-center">
                    <div className="w-full flex justify-center items-center flex-col">
                            <label htmlFor="email">Your email address</label>
                            <input onChange={handleChange} defaultValue={formState.email} name="email" className="w-72 input-base" id="email" placeholder="Your email address" />
                    </div>

                    {                        
                        errors.length > 0 && (
                            <div className="w-full mt-4">
                                <h4>Could not reset password:</h4>
                                <ul>
                                    {
                                        errors.map(error => (
                                            <li>{error}</li>
                                        ))
                                    }
                                </ul>
                            </div>
                        )
                    }

                    <div className="col-md-12 update-save-button">
                        <div className="form-group">
                            <button onClick={submit} style={{marginTop: "20px"}} className="mt-5 btn-blue" type="submit">{loading ? "Processing ..." : "Reset password"}</button>
                        </div>
                    </div>

                </div>
            </form>

        </div>
    )
}

export default withRouter(ForgotPasswordForm)
