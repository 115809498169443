import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import {FigureContainer, FigureOverlay, FigureCard, FigureCaption} from '../../styles'
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { CompleteFilter, YearFilter } from '../FigureFilter';
import FigureOptions from '../FigureOptions';






const ListPublicCollection = ({figures, alias, forSale, validYears}) => {


    const [completeFilter, setCompleteFilter] = useState("none")
    const [yearFilter, setYearFilter] = useState({
        yearFilter: "none"
    })

    const GET_YEAR_FILTER = gql`
    {
        cardedFilter @client  
        vehicleFilter @client  
        otherFilter @client 
        currentCollection @client 
    }
    `;

    const { data: yearData, client } = useQuery(GET_YEAR_FILTER);

    const yearChange = (event) => {
        //client.writeData({ data: { yearFilter: event.target.value } })
        setYearFilter({...yearFilter, yearFilter: event.target.value})
    }

    let filterData = figures

    if (completeFilter === "complete") {
        filterData = filterData.filter(x => x.missingaccessory.length === 0)
    }            
    if (completeFilter === "incomplete") {
        filterData = filterData.filter(x => x.missingaccessory.length > 0)
    }

    if (yearFilter?.yearFilter !== "none") {
        filterData = filterData.filter(x => x.figure.year.toString() === yearFilter?.yearFilter)
    }

    if (yearData?.vehicleFilter !== true) filterData = filterData.filter(x => x.figure.tags.some(y => y !== "Vehicle driver")  || x.figure.tags.length === 0)
    if (yearData?.otherFilter !== true) filterData = filterData.filter(x => x.figure.tags.some(y => y === "Vehicle driver") || x.figure.tags.length === 0)
    if (yearData?.cardedFilter !== true) filterData = filterData.filter(x => x.figure.tags.length !== 0)

   
    const completeChange = (event) => {
        setCompleteFilter(event.target.value)
    }

    const typeChange = (event) => {
        
        if (event.target.value === "carded") {
            client.writeData({ data: { cardedFilter: !yearData.cardedFilter } })  
        }

        if (event.target.value === "vehicle") {
            client.writeData({ data: { vehicleFilter: !yearData.vehicleFilter } })  
        }

        if (event.target.value === "other") {
            client.writeData({ data: { otherFilter: !yearData.otherFilter } })  
        }
    }

    console.log(validYears.filter(x => x.sharedCollection === true).map(x => x.year))
  
    return (
        <div className="container">
                <div className="w-full mt-5 md:my-5">
                    <h1>{forSale ? `For sale by ${alias}` : `Main collection by ${alias}`}</h1>
                </div>

                <div className="w-full flex flex-row py-2 sm:py-0">
                    <div className="w-full md:w-1/2 py-2 sm:py-0 mr-4">
                        <YearFilter onYearChange={yearChange} yearData={yearFilter} years={validYears.filter(x => x.sharedCollection === true).map(x => x.year)} />
                    </div>
                    <div className="w-full md:w-1/2 py-2 sm:py-0">
                        <CompleteFilter onCompleteChange={completeChange} />
                    </div>    
                </div>
                <FigureOptions yearData={yearData} typeChange={typeChange} />

  



    {filterData.length === 0 && (
        <div className="flex flex-wrap mb-4">
            <div className="w-full text-gray-400">
                    <p>There are no figures from {yearData?.yearFilter} in this collection that matches the filter</p>
            </div>
        </div>
    )}

    <div className="flex flex-wrap -mx-4 mb-4">
        {
            filterData.sort((a, b) => a.figure.name.localeCompare(b.figure.name)).map(({ id, figure, condition, missingaccessory, price, images }) => (
                    <FigureContainer  key={id} className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-2/12 px-4 mb-6 flex justify-center sm:justify-start flex-wrap">
                      <Link to={`/public/${alias}/${forSale ? "for-sale" : "collection"}/${id}`}>
                             
                            <FigureCard className="w-full">
                                <img className="w-max h-auto align-middle border-none box-border" 
                                src={images.length > 0 ? `https://gijoe-io.imgix.net/${images[0].location}?h=400&w=350&fit=crop` : process.env.REACT_APP_CMS_URL + figure.imageUrlCrop} 
                                alt="figure"
                            />
                                <FigureOverlay type={missingaccessory.length === 0 ? "complete" : "incomplete"}>
                                    {missingaccessory.length === 0 ? "Complete!" : "Missing accessory (" + missingaccessory.length +")"}
                                </FigureOverlay>
                            </FigureCard>
                            <FigureCaption className="pt-2 w-full">
                                <h6 className="font-semibold">{figure.name} v{figure.version} ({figure.year})</h6>
                                <p>Condition: {condition.name}</p>
                                {forSale && (
                                    <p style={{marginBottom: '0px'}}>Price: {price}</p>
                                )}
                            </FigureCaption>
                        </Link>    
                    </FigureContainer>

            ))
        }
    </div>
</div>        
    )
    

}

export default withRouter(ListPublicCollection)
