import styled from 'styled-components'


export const FacebookButton = styled.button`
width: 100%;
height:70px;  
    border-radius: 4px;
    background: #3b5998;
    color:white;
    border:0px transparent;  
    text-align: center;
    margin:5px;
    display: inline-block;

    &:hover{
        background: #3b5998;
        opacity: 0.6;
    }

`

export const EmailButton = styled.button`
    width: 100%;
    height:70px;  
    border-radius: 4px;
    background: grey;
    color:white;
    border:0px transparent;  
    text-align: center;
    margin:5px;
    display: inline-block;

    &:hover{
        background: #3b5998;
        opacity: 0.6;
    }

`

export const LoginOptionsContainer = styled.div`
    display: flex;
    justify-content: center;
    
    flex-direction: row;
    margin-bottom: 20px;
`