import React from 'react';
import PageHeader from '../Components/PageHeader';
import SubNavigation from '../Components/SubNavigation';
import { useQuery } from '@apollo/react-hooks';
import CATALOGFIGURE_QUERY from '../graphql/catalogfigurequery'
import OWNEDFIGURE_QUERY from '../graphql/ownedfigurequery'
import SpinnerComponent from '../Components/SpinnerComponent'
import ListMissingFigures from '../Components/MyCollection/ListMissingFigures'
import ErrorComponent from '../Components/ErrorComponent'
import ScrollToTop from '../Components/ScrollToTop';
import { useAppContext } from '../context/appcontext';


const MissingFigures = (props) => {

    const isLoggedIn = localStorage.getItem("token") !== null



    if (!isLoggedIn) {
        props.history.push("/login")
    }

    
    const { loading: catalogloading, error: catalogerror, data: catalogdata } = useQuery(CATALOGFIGURE_QUERY);   

    const {collectionId} = useAppContext();

    const { loading, error, data } = useQuery(OWNEDFIGURE_QUERY, 
        {
            variables: {
                collectionId: collectionId
            }
        });

//    const { loading, error, data } = useQuery(OWNEDFIGURE_QUERY);


    if (loading) return <SpinnerComponent LoadingText="Loading your collection..."/>;
    if (error) return <ErrorComponent />;
    if (catalogloading) return <SpinnerComponent LoadingText="Loading catalog..."/>;
    if (catalogerror) return <ErrorComponent />;
    
    var ids = data.ownedFigures.map((x => x.figure.id))
    var missingFigures = catalogdata.catalogFigures.filter(x => !ids.includes(x.id))   

    return (
        <>
            <ScrollToTop />

            <PageHeader title="Manglende figurer"/>
            <SubNavigation selected="missingFigures" />

            <ListMissingFigures missingFigures={missingFigures} />
            
        </>
    )
};


export default MissingFigures