import { useQuery } from '@apollo/react-hooks'
import React, { useState } from 'react'
import ErrorComponent from '../../Components/ErrorComponent'
import FileCardForm from '../../Components/FileCards/edit/FileCardForm'
import ScrollToTop from '../../Components/ScrollToTop'
import SpinnerComponent from '../../Components/SpinnerComponent'
import { GET_FILECARD_QUERY } from '../../graphql/queries'


const EditFileCardPage = ({history, match}) => {
    const isLoggedIn = localStorage.getItem("token") !== null
    const param = match.params.id

    const {data, loading, error} = useQuery(GET_FILECARD_QUERY, {variables: {id: param}})


    if (!isLoggedIn) {
        history.push("/login")
    }

    if (loading) return <SpinnerComponent>Loading ...</SpinnerComponent>
    if (error) return <ErrorComponent />

    return (
        <>
            <ScrollToTop />
            <FileCardForm fileCard={data.fileCard}/>
        </>
    )
}

export default EditFileCardPage