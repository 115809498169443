import React from 'react'
import addButton from '../../../images/addimage.svg'

const FileCardImageUploadButton = ({handleFile}) => {
    const hiddenImageFileInput = React.useRef(null);

    const handleImageButtonClick = event => {
        hiddenImageFileInput.current.click();
    }
    
    return (<>

                <div className="flex flex-col">
                    <div>
                        <img 
                            className=""
                            src={addButton}
                            onClick={handleImageButtonClick}
                            style={{cursor: 'pointer'}}
                        />
                    </div>
                    <div>
                        Select image
                    </div>
                </div>

                <input type="file"
                    accept="image/*"
                    ref={hiddenImageFileInput}
                    onChange={handleFile}
                    style={{display:'none'}} 
                />
    </>)
}

export default FileCardImageUploadButton