import React from 'react';
import EmailLoginForm from '../Components/Login/EmailLoginForm'
import PageHeader from '../Components/PageHeader';

const LoginPage = () => {

    return (
        <div>
            <PageHeader title="Logg inn" />

            <div className="container">
                <div className="row" style={{marginTop: '100px', marginBottom: '100px'}}>

                    <EmailLoginForm />
                 
                    <div className="col-md-12" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: "20px"}}>
                        <a href="/forgot-password">Forgot password?</a>
                    </div>
                    <div className="col-md-12" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <br /><a href="/register">Don't have an account? Click here</a>
                    </div>
                </div>
            </div>
       </div>
    )
}


    
 
export default LoginPage