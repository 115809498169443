import { useMutation } from '@apollo/react-hooks'
import React, { useState } from 'react'
import { withRouter } from 'react-router'
import { useAppContext } from '../../context/appcontext'
import { SET_USER_ALIAS, TOGGLE_COLLECTION_PUBLIC } from '../../graphql/mutations'
import { GET_COLLECTIONS_QUERY, USER_QUERY } from '../../graphql/queries'
import swal from 'sweetalert'
import MissingAlias from './MissingAlias'

const MakeCollectionPublic = ({collection, user, history}) => {


    const {dispatch} = useAppContext()

    const [formState, setFormState] = useState({
        alias: null
    })

    const [executeSetAlias] = useMutation(SET_USER_ALIAS, {
        refetchQueries: [
            {
                query: USER_QUERY
            }
        ],
        onError: () => {
            swal({
                text: "You cannot use that alias",
                icon: "error"
            })
        },
        onCompleted: () => {
            dispatch({
                type: "setUserAlias",
                payload: {
                    alias: formState.alias
                }
            })
        }
    })

    const [executeToggleCollectionPublic] = useMutation(TOGGLE_COLLECTION_PUBLIC, {
        refetchQueries: [
            {
                query: GET_COLLECTIONS_QUERY
            }
        ]
    })

    const setAlias = () => {
        if (formState.alias) {
            executeSetAlias({
                variables: {
                    alias: formState.alias
                }
            }).then(result => {
            })
        } else {
            swal({
                text: "Please enter an alias",
                icon: "error"
            })
        }
    }

    const makeCollectionPublic = () => {
        executeToggleCollectionPublic({
            variables: {
                collectionId: collection.id,
                isPublic: true
            }
        }).then(result => {
            //history.push(collection.forSale ? "/for-sale" : "/my-collection")
            dispatch({
                type: "toggleCollectionPublic",
                payload: {
                    isPublic: true
                }
            })
        })
    }

    const onInputChange = (event) => {
        setFormState({...formState, [event.target.name]: event.target.value})
    }

    const cancel = () => {
            history.push(collection.forSale ? "/for-sale" : "/my-collection")
    }


    return (<>
        <div className="container mb-10">
            <h1>Your {collection.forSale ? "For sale" : "collection"} is private</h1>
            <p className="text-gray-400">Your {collection.forSale ? "For sale" : "collection"} cannot be viewed by others. If you make your collection public you can share the link with others. People viewing your collection will not be able to edit it.</p>
            {!user.alias 
            ? (<MissingAlias onInputChange={onInputChange} setAlias={setAlias} />)
            : (
                <div className="w-full mt-5">
                    <p>Make <span className="font-bold text-red-400">{collection.forSale ? "For sale" : "Main"} collection</span> public?</p>
                    <button onClick={makeCollectionPublic} className="mt-5 btn-blue w-full">Share collection</button>
                    <button onClick={cancel} className="mt-2 btn-gray w-full">Return to collection</button>
                </div>
                )
            }
            
        </div>
    </>)
}

export default withRouter(MakeCollectionPublic)