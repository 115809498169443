import React from 'react'
import { FileUploadImage } from '../../FileUploadButton';
import FileCardImageUploadButton from './FileCardImageUploadButton';



const AddFileCardImage = ({onChange}) => {

    const getBase64 = file => {
        return new Promise(resolve => {
          let baseURL = "";
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            baseURL = reader.result;
            resolve(baseURL);
          };
        });
      };
    
    
    
    const handleFile = ({target}) => {
        let file = target.files[0]
        if (!file) return
        getBase64(file).then(result => {
            onChange(result)
        })
    }
    

    return <FileCardImageUploadButton handleFile={handleFile} />

}

export default AddFileCardImage