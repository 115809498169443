import React from "react";
import { Link } from "react-router-dom";

const AddFileCardButton = ({figureId = null, ownedFigureId = null}) => {
    const addLink = figureId === null ? "/filecards/add" : `/filecards/add/${figureId}/${ownedFigureId}`
    return (
        <div className="w-full">
            <Link to={addLink} className="btn-blue block text-center">Add filecard</Link>    
        </div>
    )
}

export default AddFileCardButton