import React from 'react';
import { Redirect } from 'react-router-dom';

const FrontPage = () => {
    return (
        <>
            <Redirect to="/my-collection" />
        </>
    )    
}

export default FrontPage