import React from 'react'
import { useApolloClient } from '@apollo/react-hooks';
import { GET_COLLECTIONS_QUERY, USER_QUERY } from '../graphql/queries';

const initialState = {
    collection: null,
    collectionId: null,
    authenticated: false,
    user: null,
    triedGetToken: null,
    token: null
}

const AppContext = React.createContext(initialState);

const AppContextProvider = ({children}) => {

    const client = useApolloClient();
    const [state, dispatch] = React.useReducer(appContextReducer, initialState)
    




    if (state.collection === null && state.token)
    {
        console.log("get collection authcontext")

        client.query({query: GET_COLLECTIONS_QUERY})
        .then(result => {
            if (result.data.collections) {
                dispatch({
                    type: "setCollection",
                    payload: {
                        collection: result.data.collections.myCollections[0],
                        collectionId: result.data.collections.myCollections[0].id,
                    }
                  })
                
            }
        }) 
    }

    if (state.user === null && state.token) {
        console.log("get user authcontext")
        client.query({query: USER_QUERY})
        .then(result => {
            if (result.data.user) {
                dispatch({
                    type: "setUser",
                    payload: {
                        user: result.data.user,
                    }
                  })
                
            }
        }) 
    }

    if (!state.triedGetToken) {
        dispatch({
            type: 'setToken',
            payload: {
                token: localStorage.getItem("token")
            }
        })
    }

    
    return (
        <AppContext.Provider value={{
            ...state,
            dispatch
            }}>
              {children}
        </AppContext.Provider>
    
    )
}

function appContextReducer(state, action) {
    switch (action.type) {
        case 'setCollection': {
            return {
                ...state,
                collection: action.payload.collection,
                collectionId: action.payload.collectionId
            }
        }

        case 'setAuthStatus': {
            return {
                ...state,
                authenticated: action.payload.authenticated
            }
        }

        case 'setTriedGetToken': {
            return {
                ...state,
                triedGetToken: action.payload.triedGetToken
            }
        }

        case 'setToken': {
            return {
                ...state,
                token: action.payload.token,
                triedGetToken: true
            }
        }

        case 'setUser': {
            return {
                ...state,
                user: action.payload.user
            }
        }

        case 'setUserAlias': {
            return {
                ...state,
                user: {
                    ...state.user, alias: action.payload.alias
                }
            }
        }

        case 'toggleCollectionPublic': {
            return {
                ...state,
                collection: {
                    ...state.collection, isPublic: action.payload.isPublic
                }
            }
        }

        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
          }
    }
}

const useAppContext = () => {
    return React.useContext(AppContext)
}

export {useAppContext, AppContextProvider, appContextReducer}



  