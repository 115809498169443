
import React, { useEffect } from 'react';
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { FigureCard, FigureCaption, FigureOverlay, FigureContainer } from '../../styles';
import { YearFilter } from '../FigureFilter';
import FigureOptions from '../FigureOptions';
import DisplayChange from '../DisplayChange';
import { withRouter } from 'react-router';

const ListMissingFiguresPublic = ({missingFigures, alias, history, location, validYears}) => {

    const urlParameters = new URLSearchParams(location.search)

   

    const GET_YEAR_FILTER = gql`
    {
        yearFilter @client
        cardedFilter @client  
        vehicleFilter @client  
        otherFilter @client  
        missingMode @client
        currentCollection @client
    }
    `;

    const { data: yearData, client } = useQuery(GET_YEAR_FILTER);

    const updateSearchParams = (parameters = {}) => {
        console.log(parameters)
        const searchParams = new URLSearchParams();
        searchParams.append("year", parameters.year !== undefined ? parameters.year : yearData.yearFilter)
        searchParams.append("carded", parameters.carded !== undefined ? parameters.carded === "true" : yearData.cardedFilter)
        searchParams.append("vehicle", parameters.vehicle !== undefined ? parameters.vehicle === "true" : yearData.vehicleFilter)
        searchParams.append("other", parameters.other !== undefined ? parameters.other === "true" : yearData.otherFilter)    
        history.replace({
            search: searchParams.toString()
         }) 

    }

    useEffect(() => {
        const urlParameters = new URLSearchParams(location.search)

        if (urlParameters.has("year")) {
            client.writeData({ data: { yearFilter: urlParameters.get("year") } })
        }
        if (urlParameters.has("carded")) client.writeData({ data: { cardedFilter: urlParameters.get("carded") === "true" } })
        if (urlParameters.has("vehicle")) client.writeData({ data: { vehicleFilter: urlParameters.get("vehicle") === "true" } })
        if (urlParameters.has("other")) client.writeData({ data: { otherFilter: urlParameters.get("other") === "true" } })
        
    }, [client, location.search]);



    const yearChange = (event) => {
        client.writeData({ data: { yearFilter: event.target.value } })
        updateSearchParams({year: event.target.value})
    }

    const typeChange = (event) => {
        if (event.target.value === "carded") {
            updateSearchParams({carded: event.target.checked})

            client.writeData({ data: { cardedFilter: event.target.checked } })  

        }

        if (event.target.value === "vehicle") {
            updateSearchParams({vehicle: event.target.checked})

            client.writeData({ data: { vehicleFilter: event.target.checked } })  
        }

        if (event.target.value === "other") {
            updateSearchParams({other: event.target.checked})

            client.writeData({ data: { otherFilter: event.target.checked } })  
        }
    }


    let filter = missingFigures
    if (yearData.yearFilter !== "none") {
        filter = filter.filter(x => x.year.toString() === yearData.yearFilter)
    }

    if (yearData.cardedFilter !== true)
    {
        filter = filter.filter(x => x.tags.length !== 0)

        //filter = filter.filter(x => x.tags.length === 0)
    }

    if (yearData.vehicleFilter !== true)
    {
        filter = filter.filter(x => x.tags.some(y => y !== "Vehicle driver")  || x.tags.length === 0)


        //filter = filter.filter(x => x.tags.length === 0)
    }
    if (yearData.otherFilter !== true)
    {
        filter = filter.filter(x => x.tags.some(y => y === "Vehicle driver") || x.tags.length === 0)

        //filter = filter.filter(x => x.tags.length === 0)
    }

    const modeChange = (mode) => {
        client.writeData({ data: { missingMode: mode } })
    }
    
    const defaultValues = {
        year: urlParameters.has("year") ? urlParameters.get("year") : yearData.yearFilter,
        carded: urlParameters.has("carded") ? urlParameters.get("carded") === "true" : yearData.cardedFilter,
        vehicle: urlParameters.has("vehicle") ? urlParameters.get("vehicle") === "true" : yearData.vehicleFilter,
        other: urlParameters.has("other") ? urlParameters.get("other") === "true" : yearData.otherFilter,
    }

    
    

    return (
            <div className="container">

                <div className="mx-auto w-full my-5">
                    <h1>Missing figures for {alias}</h1>
                </div>

                <div className="w-full flex flex-row py-2 sm:py-0">
                    <YearFilter defaultValues={defaultValues} onYearChange={yearChange} years={validYears.filter(x => x.sharedMissing === true).map(x => x.year)} />
                </div>

                <FigureOptions defaultValues={defaultValues} typeChange={typeChange} />

                {filter.length === 0 
                ? (
                    <div className="flex flex-wrap mb-4">
                        <div className="w-full text-gray-400">
                                <p>There are no figures from {yearData?.yearFilter} in this collection that matches the filter</p>
                        </div>
                    </div>
                )
                : (<>
                    <DisplayChange selectText={() => modeChange("text")} selectVisual={() => modeChange("visual")}/>
                
                </>)
            
            
            }


                    <div className="flex flex-wrap -mx-4 mb-4">
                    {
                        filter
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .sort((a, b) => a.tags.length - b.tags.length)
                            .sort((a, b) => {
                                if (a.tags[0] !== undefined && b.tags[0] !== undefined) {
                                    const aTag = a.tags[0].toString()
                                    const bTag = b.tags[0].toString()
                                    if (aTag > bTag) return 1
                                    if (bTag > aTag) return -1                                
                                }
                                return 0
                            })
                            .map(({ id, name, imageUrlCrop, version, year, slug, tags }) => (
                                yearData.missingMode === "visual" ? (
                                    <FigureContainer className="xl:w-2/12 md:w-1/3 lg:w-1/4 sm:w-1/2 w-full px-4 mb-6 flex justify-center sm:justify-start flex-col items-center" key={id}>
                                        <FigureCard>
                                                <img className="w-max h-auto align-middle border-none box-border" src={process.env.REACT_APP_CMS_URL + imageUrlCrop} alt=""/>

                                            {
                                                tags.length > 0 ?
                                                (
                                                    <FigureOverlay type="tag">{tags[0]}</FigureOverlay>
                                                )
                                                : (<></>)
                                            }
                                        </FigureCard>

                                        <FigureCaption>
                                                <h6 style={{marginBottom: '0px'}}>{name} v{version} ({year})</h6>
                                        </FigureCaption>
                                        
                                    </FigureContainer>
                                    )
                                    : (
                                        <><div className="w-full px-4 text-sm md:text-base">{name} v{version} ({year})</div></>
                                    )
                            ))
                    }
                   </div>
            </div>
    )
}

export default withRouter(ListMissingFiguresPublic)