import React from 'react';
import PageHeader from '../Components/PageHeader';
import SubNavigation from '../Components/SubNavigation';
import { withRouter } from "react-router";
import PasswordForm from '../Components/User/PasswordForm'


const PasswordPage = (props) => {

    const isLoggedIn = localStorage.getItem("token") !== null

    if (!isLoggedIn) {
        props.history.push("/login")
    }


    return (
        <React.Fragment>
            <PageHeader title="Min bruker" />
            <SubNavigation  />    
            <PasswordForm  />
        </React.Fragment>
    )
};

export default withRouter(PasswordPage)