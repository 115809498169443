import { useQuery } from '@apollo/react-hooks'
import React from 'react'
import ShowFigurePublic from '../Components/PublicCollections/ShowFigurePublic'
import ScrollToTop from '../Components/ScrollToTop'
import SpinnerComponent from '../Components/SpinnerComponent'
import { GET_PUBLIC_COLLECTION } from '../graphql/queries'

const PublicShowFigurePage = ({match}) => {

    const {data, loading, error} = useQuery(GET_PUBLIC_COLLECTION, {
        variables: {
            alias: match.params.alias,
            type: match.params.type
        }
    })

    if (loading) return <SpinnerComponent>Loading collection ...</SpinnerComponent>
    if (error) return (
        <div className="container py-10">
            <h1>Could not find collection</h1>
            <p className="text-gray-500">The collection you requested could not be found.</p>
        </div>
    )

    let collection = data.publicCollections.collection
    let figure = collection?.figures.filter(x => x.id === match.params.id)[0]
    console.log(figure)
    
    return (<>
        <ScrollToTop />
        <ShowFigurePublic figure={figure} collection={collection} />
    </>)
}

export default PublicShowFigurePage