import { useMutation } from '@apollo/react-hooks'
import React from 'react'
import { ADD_FILECARD_MUTATION, REMOVE_FILECARD_IMAGES, UPDATE_FILECARD_MUTATION } from '../../../graphql/mutations'
import { withRouter } from 'react-router-dom';
import { ALL_FILECARDS_QUERY, GET_FILECARD_QUERY, GET_OWNEDFIGURE_QUERY } from '../../../graphql/queries';

const SaveFileCardButton = ({id, catalogFigureId, image, collectionId, conditionId, notes, history, ownedFigureId = null}) => {

    const refetchQueries = [
        {
            query: ALL_FILECARDS_QUERY,
            variables: {
                collectionId: collectionId
            }   
        }
    ]

    if (ownedFigureId !== null) {
        refetchQueries.push({
            query: GET_OWNEDFIGURE_QUERY,
            variables: {
                id: ownedFigureId
            }
        })
    }

    const [executeSaveFileCard, {loading}] = useMutation(ADD_FILECARD_MUTATION, {
        onCompleted: () => {
            const redir = ownedFigureId === null ? "/filecards" : `/my-collection/${ownedFigureId}/filecards`
            history.push(redir)
        },
        onError: (error) => {
            console.log(error)
        },
        refetchQueries
    })

    const [executeUpdateFileCard, {loading: updateLoading}] = useMutation(UPDATE_FILECARD_MUTATION, {
        onCompleted: () => {
            const redir = ownedFigureId === null ? "/filecards" : `/my-collection/${ownedFigureId}/filecards`
            history.push(redir)
        },
        refetchQueries
    })

    const [executeRemoveFileCardImages, {loading: removeFileCardImagesLoading}] = useMutation(REMOVE_FILECARD_IMAGES, {
        refetchQueries: [
            {
                query: ALL_FILECARDS_QUERY,
                variables: {
                    collectionId: collectionId
                }   
            }
        ],

    })


    const save = () => {
    
        let images = image !== null ? [{fileCardId: null, imageBase64: image}] : null

        let variables = {            
            request: {
                catalogFigureId,
                collectionId,
                conditionId,
                images,
                notes    
            }
        }

        console.log(variables)

        executeSaveFileCard({
            variables
        })
    }

    const update = () => {
        let images = (image !== null && !image.startsWith("http"))
            ? [{fileCardId: id, imageBase64: image}]
            : null

        if (image === null)
        {
            let variables = {
                id: id
            }
            
            executeRemoveFileCardImages({
                variables
            })
        }
        
        let variables = {
            id,
            request: {
                catalogFigureId,
                collectionId,
                conditionId,
                images,
                notes,                
            }
        }

        console.log(variables)

        executeUpdateFileCard({
            variables
        })
    }

    return id === null 
            ? <div className="btn-blue w-full cursor-pointer text-center mb-5" onClick={save}>{loading ? "Saving ..." : "Save"}</div>
            : <div className="btn-blue w-full cursor-pointer text-center mb-5" onClick={update}>{updateLoading ? "Saving ..." : "Update"}</div>

}

export default withRouter(SaveFileCardButton)