import React from 'react'
import ListFileCards from '../FileCards/list/ListFileCards'
import EditFigureMenu from './EditFigureMenu'

const FigureFileCardComponent = ({ownedFigure}) => {
    return (
        <>
            <div className="container mb-20">
                <div className="w-full flex justify-center items-center mb-2 sm:mb-6 flex-col">
                    <h1 className="mb-0 text-xl lg:text-4xl">{ownedFigure.figure.name} ({ownedFigure.figure.type})</h1>
                </div>

                <EditFigureMenu figureId={ownedFigure.id} />

                <ListFileCards fileCards={ownedFigure.fileCards} figureId={ownedFigure.figure.id} ownedFigureId={ownedFigure.id} />
            </div>
        </>
    )
}

export default FigureFileCardComponent