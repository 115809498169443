import React, { useState } from 'react'
import FileCardForm from '../../Components/FileCards/edit/FileCardForm'
import ScrollToTop from '../../Components/ScrollToTop'


const AddFileCardPage = ({history, match}) => {
    const isLoggedIn = localStorage.getItem("token") !== null

    if (!isLoggedIn) {
        history.push("/login")
    }

    return (
        <>
            <ScrollToTop />
            <FileCardForm 
                figureId={match.params.id === undefined ? null : match.params.id}
                ownedFigureId={match.params.ownedfigureid === undefined ? null : match.params.ownedfigureid}
            />
        </>
    )
}

export default AddFileCardPage