import React, {useState} from 'react';
import { useMutation } from '@apollo/react-hooks';
import OWNEDFIGURE_QUERY from '../../graphql/ownedfigurequery'
import { withRouter } from 'react-router-dom';
import {ADD_OWNEDFIGURE} from '../../graphql/mutations';
import { Button } from '../../styles';
import { useAppContext } from '../../context/appcontext';
import StarRatingComponent from 'react-star-rating-component';
import swal from 'sweetalert'
import ManageAccessories from '../ManageAccessories';





const AddFigure = ({conditions, missingFigures, addedFigureId, allFigures, collection, history}) => {



    const [conditiondata] = useState(conditions)
    const [addedFigure] = useState(addedFigureId)

    const selectedFigure = allFigures.filter(x => x.id === addedFigureId)[0]



    let {forSale} = collection

    const [formState, setFormState] = useState({
        figure: addedFigure, 
        condition: "none", 
        note: "",
        joints: 3,
        oring: 3,
        paint: 3,
        screws: 3,
        brokenparts: false,
        elbowcracks: false,
        price: 0,
        accessories: selectedFigure.accessory.length > 0
            ? selectedFigure.accessory.map(accessory => {
                return {
                    id: accessory.id,
                    owned: false
                }
            })
            : null
    })



    const [addedId, setAddedId] = useState("")

    const {collectionId} = useAppContext();


    const [addFigure, { loading: addFigureLoading }] = useMutation(ADD_OWNEDFIGURE, {
        onCompleted() {
            history.push(forSale ? "/for-sale/" + addedId : "/my-collection/" + addedId);
            return
          },
        refetchQueries: [
            {
                query: OWNEDFIGURE_QUERY,
                variables: {
                    collectionId: collectionId
                }   
            }
        ],
        awaitRefetchQueries: true
        });


    const handleChange = (event) => {
        setFormState({...formState, [event.target.name]: event.target.value})
    }

    const submit = () => {
        if (formState.condition === "none" || formState.figure === "none")
        {
            swal({
                title: "Error",
                text: "You must choose figure and condition",
                icon: "error"
            })
            return   
        }

        var accessories = formState.accessories !== null
            ? formState.accessories.filter(x => x.owned === true).map(x => {
                return {
                    externalId: x.id,
                    conditionId: formState.condition,
                }})
            : null
        

        console.log(accessories)

        var vars = {
            "ownedFigure": {
                "externalId": formState.figure, 
                "conditionId": formState.condition, 
                "note": formState.note,
                "collectionId": collectionId,
                "joints": formState.joints,
                "oring": formState.oring,
                "paint": formState.paint,
                "screw": formState.screws,
                "elbowCracks": formState.elbowcracks,
                "brokenParts": formState.brokenparts,
                "price": parseInt(formState.price)
            },
            accessories
        }

        addFigure({
            variables: vars,
            update: (cache, response) => {               
                setAddedId(response.data.createOwnedFigure.id);
            }
        });
        
    }

    const onCancelClick = () => {
        history.push(forSale ? "/for-sale" : "/my-collection")
    }

    const onStarClick = (nextValue, prevValue, name) => {
        setFormState({...formState, [name]: nextValue})
      }

      const handleToggle = (event) => {
        setFormState({ ...formState, [event.target.name]: event.target.checked })
    }

    const accessoryChange = ({target}) => {
        let accessories = formState.accessories       
        const index = accessories.findIndex(x => x.id === target.name)
        accessories[index].owned = target.checked
        setFormState({...formState, accessories})
    }

    

    return (
        <>
             <div className="container mb-5">
                 <div className="flex-col w-full flex md:flex-row">
                    <div className="hidden md:flex w-full md:w-4/12 flex-col items-start flex-grow mt-2">
                        <div className="w-full flex justify-center md:justify-start ">
                            <img 
                                className="w-full md:w-3/4 lg:w-10/12" 
                                alt="Bilde av {figure.figure.name}"
                                src={`${process.env.REACT_APP_CMS_URL + selectedFigure.imageUrlRaw}?width=600`}
                            />
                            
                        </div>
                
                    </div>
                    <div className="w-full md:w-8/12">
                        <div className="w-full sm:w-1/2">
                            <h3 className="text-lg font-bold">{`Add ${selectedFigure.name} v${selectedFigure.version} (${selectedFigure.year})`}</h3>
                        </div>


                        <div className="w-full  my-2">
                            <div className="form-group">
                                <label htmlFor="sitetype">General condition</label>

                                <select name="condition" onChange={handleChange} defaultValue="none" className="w-full input-base">
                                    <option value="none" disabled>Choose condition</option>
                                    {
                                        conditiondata.conditions.map(({id, name}) => (
                                        <option key={id} value={id}>{name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>

                        <div className="w-full flex flex-wrap my-5">
                            <div className="w-1/2 md:w-1/4">
                                <div className="form-group">
                                    Joints<br/> 
                                    <StarRatingComponent 
                                        name="joints"
                                        starCount={6}
                                        value={formState.joints}
                                        onStarClick={onStarClick}
                                    />
                                </div>
                            </div>

                            <div className="w-1/2 md:w-1/4">
                                <div className="form-group">
                                    O-Ring<br/> 
                                    <StarRatingComponent 
                                        name="oring"
                                        starCount={6}
                                        value={formState.oring}
                                        onStarClick={onStarClick}

                                    />
                                </div>
                            </div>


                            <div className="w-1/2 md:w-1/4">
                                <div className="form-group">
                                    Paint<br/> 
                                    <StarRatingComponent 
                                        name="paint"
                                        starCount={6}
                                        value={formState.paint}
                                        onStarClick={onStarClick}

                                    />
                                </div>
                            </div>
                            <div className="w-1/2 md:w-1/4">
                                <div className="form-group">
                                    Screws<br/> 
                                    <StarRatingComponent 
                                        name="screws"
                                        starCount={6}
                                        value={formState.screws}
                                        onStarClick={onStarClick}

                                    />
                                </div>
                            </div>


                            <div className="w-full mt-2">
                                    <input type="checkbox" 
                                        name="brokenparts"
                                        id="brokenparts"
                                        onChange={handleToggle}
                                    /><label htmlFor='brokenparts'>&nbsp;Has any broken parts</label>
                            </div>
                            <div className="w-full mt-1">
                                    <input type="checkbox" 
                                        name="elbowcracks"
                                        id="elbowcracks"
                                        onChange={handleToggle}
                                    />
                                <label htmlFor='elbowcracks'>&nbsp;Has any elbow cracks</label>                            
                            </div>

                        </div>

                        <div className="w-full pb-2">
                        {selectedFigure.accessory.length > 0
                            ? <ManageAccessories onChange={accessoryChange} accessory={selectedFigure.accessory} />
                            : <></>
                        } 
                        </div>

                        {forSale && (
                        <div className="w-full my-2">
                                <label htmlFor="price">Price</label><br/>
                                <input className="input-base" defaultValue={0} type="number" name="price" onChange={handleChange} />
                        </div>
                        )}


                        <div className="w-full my-2">
                                <label htmlFor="note">Note</label>
                                <textarea rows={7} name="note" onChange={handleChange} className="input-base w-full" id="externalId" placeholder="Note" />
                        </div>
                        <div className="w-full my-1">
                                <Button onClick={submit} className="w-full mt-5 btn-blue">{addFigureLoading ? "Processing ..." : "Add figure to collection"}</Button>
                        </div>
                        <div className="w-full my-1">
                                <button onClick={ onCancelClick } className="w-full mt-5 btn-gray" >Cancel</button>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default withRouter(AddFigure)