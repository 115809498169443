import React from 'react'

const Footer = () => {
    return (
        <>
            <div className="w-full h-32 bg-gray-700 flex items-center">
                <div className="flex items-center justify-center flex-grow">
                  <a href="https://www.gijoe.io/privacy">Privacy policy</a>         
                </div>
            </div>
        </>
    )
}

export default Footer