import React from 'react';
import { withRouter, Link } from 'react-router-dom';

import { useApolloClient } from '@apollo/react-hooks';


const ProfileMenu = (props) => {

    const client = useApolloClient();

    const logout = () => {
        localStorage.clear("token")
        client.resetStore()
        props.history.push('/login')
    }

    return (
        <>
            <div className="flex flex-row-reverse relative pl-20 items-center invisible lg:visible">
                <ul className="list-none m-0 p-0 overflow-hidden">
                    <li className="float-left mx-2">
                        <Link title="Din bruker" to="/user"><img alt="user button" width="20" src="/img/user.png" /></Link>
                    </li>
                    <li className="float-left mx-2">
                        <img className="cursor-pointer" onClick={logout} alt="logout button" width="20" src="/img/exit.png" />
                    </li>
                </ul>
            </div>                 
        </>
    )
};

export default withRouter(ProfileMenu)