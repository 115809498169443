import React from 'react';
import { Link } from 'react-router-dom';


const UserNavigation = () => (

    <div className="w-full m-5 flex flex-wrap justify-center">
        <div className="flex flex-row justify-center border-solid border-2">
            <ul className="list-none m-0 overflow-hidden">
                <li className="float-left p-4"><Link to="/user">Profile</Link></li>
                <li className="float-left p-4 "><Link to="/user/password">Password</Link></li>            
            </ul>        
        </div>
    </div>

)

export default UserNavigation


