import React from 'react'
import Footer from '../Footer'
import { Navigation } from './Navigation'

//import patreon_logo from '../../images/become_a_patron_button.png'


const Layout = ({children}) => {

    return (
        <>
            <Navigation />

            <section className="mt-2 lg:mt-8 block pb-0 px-4">
                {children}
                {/*
                <div className="container" style={{display: "flex", alignItems: "center", flexDirection: "column", marginTop: "10px"}}>
                <a href="https://www.patreon.com/bePatron?u=39684783"><img width="250" src={patreon_logo} alt="patreon" /></a>
                </div>
                */}
            </section>
            <Footer />
        </>
    )
}

export default Layout