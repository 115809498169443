import styled from 'styled-components'

export const Nav = styled.nav`
   


`

export const NaviLi = styled.li`
    padding-top: 8px;
`
