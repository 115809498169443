import React, {useState} from 'react';
import { LOGIN } from '../../graphql/mutations'
import { useMutation } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';
import { useApolloClient } from '@apollo/react-hooks';
import { GET_COLLECTIONS_QUERY } from '../../graphql/queries';
import { useAppContext } from '../../context/appcontext';

const EmailLoginForm = (props) => {
    const initialState = {
        username: "",
        password: ""
    }


    const [errors, setErrors] = useState([])
    const [formState, setFormState] = useState(initialState)
    const client = useApolloClient();
    const {dispatch, collection} = useAppContext();




    const [doLogin, { loading }] = useMutation(LOGIN, {
        onCompleted({login}) {  
            
            localStorage.setItem("token", login.token)     
            
            dispatch({
                type: 'setToken',
                payload: {
                    token: login.token
                }
            })

            client.writeData({
                data: {
                    yearFilter: 'none',
                    cardedFilter: true,
                    vehicleFilter: true,
                    otherFilter: true,
                    missingMode: 'visual',
                    currentCollection: null
                }
            })
            if (!collection)
            {
                client.query({query: GET_COLLECTIONS_QUERY}).then(result => {
                    let myCollection = result.data.collections.myCollections.filter(x => x.forSale === false && x.allowDuplicates === false)[0]
                        dispatch({
                            type: "setCollection",
                            payload: {
                                collection: myCollection,
                                collectionId: myCollection?.id,
                            }
                        })
                    
                    
                })
            }
    
    
            props.history.push("/my-collection")
            return
          },
        onError: ({ graphQLErrors, networkError }) => {
            let stateErrors = []
            graphQLErrors.forEach(x => {
                stateErrors.push([x.message.replace("GraphQL.ExecutionError: ", "")])
            })
            setErrors(stateErrors)
        }

        });

    function handleChange(event)
    {
        let state = formState
        state[event.target.name] = event.target.value
        setFormState(state)
    }

    function submit() {
        //localStorage.setItem("token", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6ImZkYWI3ZTE1LTgzZTctNGJhMi04ZmQxLTg0MWI1NTdiYjQwZSIsIm5iZiI6MTU3MTIzODUyNCwiZXhwIjoxNTcxODQzMzI0LCJpYXQiOjE1NzEyMzg1MjR9.8kuSthU-oZDYb-_XGRqWIOyZDO1oxj9g0DQGNeuMl_c")
        setErrors([])
        if (formState.username === "" || formState.password === "") {            
            setErrors(["Du må skrive inn et brukernavn og passord"])   
            return         
        }


        doLogin({
            variables: {
                login: {
                    email: formState.username,
                    password: formState.password
                }
            }
        })
    }


    return (
        <>
            <div className="w-full flex flex-col items-center">
                <label htmlFor="username">Email</label>
                <input onChange={handleChange} name="username" className="input-base w-72 lg:w-96" id="username" placeholder="Email" />
            </div>
            <div className="w-full flex flex-col items-center">
                    <label htmlFor="password">Password</label>
                    <input onChange={handleChange} name="password" type="password" className="w-72 lg:w-96 input-base" id="externalId" placeholder="Password"  />
            </div>
    {
        errors.length > 0 ? (
        <div className="w-full flex flex-col items-center text-red-600 mt-3">
        <h4>Could not log you in:</h4>
        <ul>                                           
        {/*
            errors.map(x => (
                        <li key={x}>{x}</li>
            ))
        */}
            <li>Invalid username/password</li>
            </ul>
        </div>
        )
        : (
            <React.Fragment></React.Fragment>
        )

    }

        <div className="w-full flex flex-col items-center">
                <button className="mt-5 btn-blue w-72 lg:w-96" onClick={submit}>{loading ? "Processing ..." : "Log in"}</button>
        </div>

        </>
    )
}

export default withRouter(EmailLoginForm)