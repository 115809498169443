import React, { useState } from 'react'
import { Link, withRouter } from "react-router-dom";
import { useAppContext } from '../../../context/appcontext';
import FigureSearch from '../../Common/FigureSearch'
import SelectCondition from '../../Common/SelectCondition'
import AddFileCardImage from '../../FileCards/edit/AddFileCardImage'
import SaveFileCardButton from '../../FileCards/edit/SaveFileCardButton'
import deleteIcon from '../../../images/delete-icon.png'


const FileCardForm = ({fileCard = null, figureId=null, ownedFigureId = null}) => {
    const {collectionId} = useAppContext()


    
     
    const [formState, setFormState] = useState(
        fileCard === null ? {
            uploadedImage: null,
            figure: figureId !== null ? figureId : null,
            conditionId: null,
            notes: null
        } : {
            uploadedImage: fileCard.fileCardImages.length > 0 ? `https://gijoe-io.imgix.net/${fileCard.fileCardImages[0].location}` : null,
            figure: fileCard.figure.id,
            conditionId: fileCard.condition.id,
            notes: fileCard.notes,
        } 
        
    )

    const onChange = (figure) => {
        setFormState({...formState, ['figure']: figure.value})
    }

    const onChangeImage = (image) => {
        setFormState({...formState, ['uploadedImage']: image})
    }

    const onConditionChange = (e) => {
        setFormState({...formState, ['conditionId']: e.target.value})
   }

    const onNotesChange = (e) => {
        setFormState({...formState, ['notes']: e.target.value})
    }



    return (
        <div className="container mb-5 justify-center">
        <h1>{fileCard ? "Edit filecard" : "Add filecard"}</h1>
        {ownedFigureId === null && (
            <>
                <h3>Select figure</h3>
                <div className="flex justify-center">
                    <FigureSearch 
                        onChange={onChange}
                        defaultValue={formState.figure}
                    />
                </div>
            </>
        )}
        <div className="flex justify-center w-full py-5">
            <SelectCondition 
                onChange={onConditionChange}
                defaultValue={formState.conditionId}
            />
        </div>

        <div className="w-full pb-5">
            <label htmlFor="note">Note</label>
            <textarea rows={7} onChange={onNotesChange} defaultValue={formState.notes} name="note" className="input-base w-full" placeholder="Note" />
        </div>

        {formState.uploadedImage !== null && (
            <div className="flex w-full lg:w-1/3 flex-col">
                <img 
                        src={deleteIcon} 
                        className="absolute w-8 self-end pr-4 cursor-pointer pt-8" 
                        onClick={() => {setFormState({...formState, ['uploadedImage']: null})
                    }}
                    />        
                <img className="py-5 w-full" src={formState.uploadedImage} />
            </div>
        )}

        <div className="justify-center flex py-5">
            <AddFileCardImage 
                onChange={onChangeImage}
            />
        </div>



        <SaveFileCardButton 
            catalogFigureId={formState.figure} 
            image={formState.uploadedImage} 
            collectionId={collectionId} 
            conditionId={formState.conditionId}
            notes={formState.notes}
            id={fileCard ? fileCard.id : null}
            ownedFigureId={ownedFigureId}
        />

        <Link to="/filecards"><div to="/filecards" className="btn-gray w-full cursor-pointer text-center mb-5">Cancel</div></Link>
        
    </div>

    )

}

export default withRouter(FileCardForm)