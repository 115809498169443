import { useApolloClient } from '@apollo/react-hooks';
import React from 'react'
import { Route } from 'react-router';
import Layout from '../Components/Common/Layout';
import { useAppContext } from '../context/appcontext';
import { GET_COLLECTIONS_QUERY } from '../graphql/queries';

const ForSaleRoute = ({ children, ...rest }) => {
    const client = useApolloClient()
    var {dispatch, collection} = useAppContext()

    let isForSale = (collection?.forSale === true)

    if (!isForSale && collection) {
        console.log("fetching collection yooo")
        client.query({query: GET_COLLECTIONS_QUERY}).then(result => {
            let forSaleCollection = result.data.collections.myCollections.filter(x => x.forSale === true)[0]
                if (forSaleCollection) {
                    dispatch({
                      type: "setCollection",
                      payload: {
                          collection: forSaleCollection,
                          collectionId: forSaleCollection?.id,
                      }
                  })
                }
    })}

    return (

        <Layout>
          <Route
            {...rest}
          />
        </Layout>

    );
  }
  
export default ForSaleRoute