import React, {useState} from 'react';
import { useMutation } from '@apollo/react-hooks';

import {UPDATE_USER} from '../../graphql/mutations';
import {USER_QUERY} from '../../graphql/queries'

import { withRouter } from 'react-router-dom';
import UserNavigation from './UserNavigation'
import { Button } from '../../styles';

import swal from 'sweetalert'

const UserForm = (props) => {
    const [user] = useState(props.user)   

    const initialState = {
        firstname: user.firstName,
        lastname: user.lastName,
        acceptnewsletter: user.acceptNewsletter
    }

    const [formState, setFormState] = useState(initialState)
    const [errors, setErrors] = useState([])

    const [doUpdateUser, { loading }] = useMutation(UPDATE_USER, {
        onCompleted() {
            swal(
                {
                    title: "User updated!",
                    button: "success"
                }
            )
          },
          onError: ({ graphQLErrors }) => {
            graphQLErrors.forEach(x => {
                setErrors([...errors, [x.message.replace("GraphQL.ExecutionError: ", "")]])
            })
        },
        refetchQueries: [{query: USER_QUERY}],
        awaitRefetchQueries: true
        });


    const handleChange = (event) => {
        let state = formState
        state[event.target.name] = event.target.value       
        setFormState(state)

    }

    const handleChecked = (event) => {
        let state = formState
        state[event.target.name] = event.target.checked
        setFormState(state)
    }


    const submit = (event) => {
        event.preventDefault()

        if (formState.firstname === "" || formState.lastName ==="") {
            setErrors([["First and/or lastname cannot be empty"]])
            return
        }

        let user = {
            firstName: formState.firstname,
            lastName: formState.lastname,
            acceptNewsletter: formState.acceptnewsletter
        }
        doUpdateUser({
            variables: {
                user: user
            }
        })
    }




    return (
        <div className="container">
            <UserNavigation />
            <form>
                <div className="m-5">
                    <div className="w-full py-2">
                        <div className="form-group">
                            <label htmlFor="firstname">First name</label>
                            <input onChange={handleChange} defaultValue={formState.firstname} name="firstname" className="block w-full text-black mt-1 py-2 px-3 border border-gray-300 bg-white  shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" id="firstname" placeholder="First name" />
                        </div>
                    </div>
                    <div className="w-full py-2">
                        <div className="form-group">
                            <label htmlFor="lastname">Last name</label>
                            <input onChange={handleChange} defaultValue={formState.lastname} name="lastname" className="block w-full text-black mt-1 py-2 px-3 border border-gray-300 bg-white  shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" id="lastname" placeholder="Last name"  />
                        </div>
                    </div>
                    <div className="w-full py-2">
                        <div className="form-group">
                            <input onChange={handleChecked} defaultChecked={formState.acceptnewsletter} name="acceptnewsletter" type="checkbox" /> Receive updates from gijoe.io on email
                        </div>
                    </div>




                    {
                        errors.length > 0
                        ? (
                            <div className="col-md-12">
                                <h4>Cannot update user:</h4>
                                <ul>
                                    {
                                        errors.map(error => (
                                            <li>{error}</li>
                                        ))
                                    }
                                </ul>
                            </div>
                        )
                        : (
                            <React.Fragment></React.Fragment>
                        )
                    }

                    <div className="col-md-12 update-save-button">
                        <div className="form-group">
                            <Button onClick={submit} style={{marginTop: "20px"}} className="w-full mt-5 bg-blue-500 font-bold text-white px-4 py-3 transition duration-300 ease-in-out hover:bg-blue-600 mr-6" type="submit">{loading ? "Processing ..." : "Update user"}</Button>
                        </div>
                    </div>

                </div>
            </form>

        </div>
    )
}

export default withRouter(UserForm)
