import React from 'react'

const ManageAccessories = ({onChange, accessory}) => {
    return (
        <div className="w-full">
            <h3 className="text-xl font-semibold my-4">Accessories</h3>

            <div className="w-full">
                <div className="flex items-baseline flex-col">
                    {accessory.map(({id, name, owned}, index) => (
                        <div key={`div-${index}`}>
                            <input id={id} rel={id} name={`${id}`} type="checkbox" defaultChecked={owned} value={name} onClick={onChange}/><label style={{marginLeft: "5px"}} htmlFor={id} >{name}</label>                                     
                        </div>
                    ))}
                </div>
            </div>                                
        </div>
    )
}

export default ManageAccessories