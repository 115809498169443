import { gql } from 'apollo-boost';



const OWNEDFIGURE_QUERY = gql`
    query($collectionId: UUID) {
        ownedFigures(collectionId: $collectionId) {
            id           
            condition {id, name}
            figure {id, name, imageUrlRaw, imageUrlCrop, version, year, slug, tags, type}
            missingaccessory {id, name}
            ownedaccessory {id, name}
            note
            price
            joints
            oring
            paint
            screw
            brokenParts
            elbowCracks
            
            images {
                id
                location
            }
        }

        missingFigures(collectionId: $collectionId) {
            id
            name
            imageUrlRaw
            imageUrlCrop
            version
            year
            slug
            tags
        }
    } 
`;

export default OWNEDFIGURE_QUERY