import gql from 'graphql-tag';

export const REMOVE_FILECARD_IMAGES = gql`
    mutation($id: UUID!) {
        removeAllFileCardImages(id: $id)
    }
`


export const UPDATE_FILECARD_MUTATION = gql`
    mutation($id: UUID!, $request: FileCardRequestInput!) {
        updateFileCard(id: $id, request: $request) {
            id
            figure {
                id
                name
            }
            condition {
                id
                name
            }
            fileCardImages {
                id
                location
            }
            notes
        }
    }
`


export const REMOVE_FILECARD_MUTATION = gql`
    mutation($id: UUID!) {
        removeFileCard(id: $id)
    }
`

export const ADD_FILECARD_MUTATION = gql`
    mutation($request: FileCardRequestInput!) {
        addFileCard(request: $request) {
            id
            figure {
                id
                name
            }
            condition {
                id
                name
            }
            fileCardImages {
                id
                location
            }
            notes
        }
    }
`

export const ADD_OWNEDFIGURE = gql`
    mutation($ownedFigure:ownedFigureInput!, $accessories: [accessoryInput]) 
    {
        createOwnedFigure(ownedFigure:$ownedFigure, accessories: $accessories) 
        {
            id 
            note
        }
    }
`;

export const UPDATE_OWNEDFIGURE = gql`
    mutation($ownedFigure: ownedFigureInput!, $id: UUID!, $accessory: [accessoryInput]) 
    {
        updateOwnedFigure(ownedFigure: $ownedFigure, id: $id, accessory: $accessory)
        {
            id           
            condition {id, name}
            figure {id, name, imageUrlCrop, imageUrlRaw, version, year, slug, tags}
            missingaccessory {id, name}
            ownedaccessory {id, name}
            note
        }
    }
`;

export const REMOVE_OWNEDFIGURE = gql`
    mutation($id: UUID!) 
    {
        removeOwnedFigure(id: $id)
    }
`;

export const LOGIN = gql`
    mutation($login:loginInput!)
    {
        login(login: $login)
        {
            token
        }
    }
`;

export const FACEBOOK_LOGIN = gql`
    mutation($accessToken:String!)
    {
        token: facebookLogin(accessToken: $accessToken)
    }
`;

export const UPDATE_USER = gql`
    mutation($user: updateUserInput!) {
        updateUser(user:$user) {
            username
            firstName
            lastName
            fullName
            email
            acceptNewsletter
        }
    }
`;

export const UPDATE_PASSWORD = gql`
    mutation($password: passwordInput!) {
        changePassword(password: $password)
    } 
`;

export const REGISTER_USER = gql`
mutation($user: userInput!)
{
  createUser(user: $user) {
      username 
      email 
      fullName
      token
    }
}
`;

export const REQUEST_PASSWORD_RESET = gql`
    mutation($email: String!) {
        requestPasswordResetLink(email: $email)
    }
`;

export const RESET_PASSWORD = gql`
    mutation($request:resetPasswordInput!) {
        resetPassword(resetPassword: $request)
    }
`;

export const ADD_IMAGE_TO_OWNED_FIGURE = gql`
    mutation($request: OwnedFigureImageInput!) {
        addOwnedFigureImage(request: $request) {
            id
            ownedFigureId
        }
    }
`
export const REMOVE_IMAGE = gql`
    mutation($imageId: UUID!) {
        deleteOwnedFigureImage(imageId: $imageId)
    }
`
export const ADD_FOR_SALE_COLLECTION = gql`
    mutation {
        collections {
            createForSaleCollection {id name}
        }
    }
`
export const SET_USER_ALIAS = gql`
    mutation($alias: String!) {
        setUserAlias(alias: $alias) 
    }
`

export const TOGGLE_COLLECTION_PUBLIC = gql`
    mutation($collectionId: UUID!, $isPublic: Boolean!) {
        collections {
            toggleCollectionPublic(collectionId: $collectionId, isPublic: $isPublic) {
                id
                name
                isPublic
            }
        }
    }
`

export const SET_SHARED_YEARS = gql`
    mutation($collectionId: ID!, $isPublic: Boolean!, $sharedYears: [ValidYearInput]) {
        collections {
            toggleCollectionPublic(collectionId: $collectionId, isPublic: $isPublic, sharedYears: $sharedYears) {
                id
                name
                isPublic
            }
        }
    }
`



