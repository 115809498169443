import React from 'react'

export const YearFilter = ({yearData, defaultValues, onYearChange, years}) => {
    return (
        <select defaultValue={yearData ? yearData.yearFilter : defaultValues.year} onChange={onYearChange} className="w-full input-base">
            <option value="none">All years</option>
            {
                years.map(year => (
                    <option key={year} value={year}>{year}</option>
                ))
            }
        </select>
    )
}

export const CompleteFilter = ({onCompleteChange}) => {
    return (
        <select defaultValue="none" onChange={onCompleteChange} className="w-full input-base">
            <option value="none">All figures</option>
            <option value="complete">Complete figures</option>
            <option value="incomplete">Incomplete figures</option>
        </select>
    )
}

