import { gql } from 'apollo-boost';

const CONDITIONS_QUERY = gql`
{
    conditions {
        id
        name
    }
}`;

export default CONDITIONS_QUERY