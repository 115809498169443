import React from 'react'
import { Link } from 'react-router-dom'
import noImage from '../../../images/noimage.png'

import DeleteFileCardButton from '../delete/DeleteFileCardButton'

const FileCardItem = ({fileCard, figureId, ownedFigureId = null}) => {
    const image = fileCard.fileCardImages.length > 0 
    ? `https://gijoe-io.imgix.net/${fileCard.fileCardImages[0].location}`
    : noImage

    return (
        <div className="w-full lg:w-1/3 py-5 px-5 md:py-3 flex flex-col">

                <DeleteFileCardButton fileCard={fileCard} ownedFigureId={ownedFigureId} />
                <Link to={`/filecards/${fileCard.id}`}>

                <img className="w-full" src={image} />
                {figureId === null && (<h2 className="text-center mb-3">{fileCard.figure.name}</h2>)}
            </Link>
        </div>
        
    )
}

export default FileCardItem