import { useMutation } from '@apollo/react-hooks'
import React, { useState } from 'react'
import { withRouter } from 'react-router'
import swal from 'sweetalert'
import { useAppContext } from '../../context/appcontext'
import { SET_SHARED_YEARS, TOGGLE_COLLECTION_PUBLIC } from '../../graphql/mutations'
import { GET_COLLECTIONS_QUERY } from '../../graphql/queries'

const HideCollection = ({collection, history}) => {

    const {user} = useAppContext()

    const {dispatch} = useAppContext()

    const [sharedYears, setSharedYears] = useState(collection.validYears.sharedYears)


    const collectionYearChange = index => e => {
        let state = sharedYears
        state[index].sharedCollection = !state[index].sharedCollection
        setSharedYears(state)
    }

    const missingYearChange = index => e => {
        let state = sharedYears
        state[index].sharedMissing = !state[index].sharedMissing
        setSharedYears(state)
        console.log(sharedYears)
       
    }

    const updateSharedYears = () => {
        executeSetSharedYears({
            variables: {
                collectionId: collection.id,
                isPublic: true,
                sharedYears: sharedYears.map(x => ({
                    year: x.year,
                    sharedAccessory: x.sharedAccessory,
                    sharedCollection: x.sharedCollection,
                    sharedMissing: x.sharedMissing

                }))
            }
        }).then(result => {
            swal(
                {
                    text: "Shared years updated",
                    icon: "success"
                }
            )
        })
    }

    const [executeToggleCollectionPublic] = useMutation(TOGGLE_COLLECTION_PUBLIC, {
        refetchQueries: [
            {
                query: GET_COLLECTIONS_QUERY
            }
        ]
    })

    const [executeSetSharedYears] = useMutation(SET_SHARED_YEARS, {
        refetchQueries: [
            {
                query: GET_COLLECTIONS_QUERY
            }
        ]
    })

    const removePublicAccess = () => {
        executeToggleCollectionPublic({
            variables: {
                collectionId: collection.id,
                isPublic: false
            }
        }).then(result => {
            dispatch({
                type: "toggleCollectionPublic",
                payload: {
                    isPublic: false
                }
            })

        })
    }

    const cancel = () => {
            history.push(collection.forSale ? "/for-sale" : "/my-collection")
    }

    let publicLink = `${window.location.protocol}//${window.location.host}/public/${user.alias}/${collection.forSale ? "for-sale" : "collection"}`
    let missingLink = `${window.location.protocol}//${window.location.host}/public/${user.alias}/collection/missing`
    let accessoryLink = `${window.location.protocol}//${window.location.host}/public/${user.alias}/collection/missing-accessories`    

    console.log(collection.validYears)

    return (<>
        <div className="container mb-10">
            <div className="flex w-full mb-5 flex-wrap flex-col md:flex-row">
                <div className='md:flex-grow flex'>
                    <h1>Your {collection.forSale ? "For sale" : "collection"} is shared</h1>
                </div>
                <div className="justify-right flex">
                    <button onClick={cancel} className="btn-gray w-full md:w-auto mr-2 mt-5">Return to collection</button>
                    <button onClick={removePublicAccess} className="mt-5 btn-red w-full md:w-auto">Stop sharing collection</button>
                </div>
            </div>
            
            <div className="text-gray-400">
                Users with the link to your collection can view the collection, but not edit it. If you want to stop sharing your collection, click "Stop sharing collection". This action will only remove public access to your collection, it will not affect your collection otherwise.
            </div>

            <div className="">
                <div className="w-full lg:w-1/2 grid grid-cols-3 lg:grid-cols-2 mt-5">
                    <div className="col-span-2 lg:col-span-1">
                        Shared collection: 
                    </div>
                    <div>
                        <a href={publicLink} target="_blank" rel="noopener noreferrer">[Open]</a> <button style={{color: "#84c9fb"}} className="cursor-pointer" onClick={() => navigator.clipboard.writeText(publicLink)}>[Copy]</button>
                    </div>
                </div>

                {!collection.forSale && (
                    <>
                        <div className="w-full lg:w-1/2 grid grid-cols-3 lg:grid-cols-2">
                            <div className="col-span-2 lg:col-span-1">
                            Shared missing figures:   
                            </div>
                            <div className="">
                                <a href={missingLink} target="_blank" rel="noopener noreferrer">[Open]</a> <button style={{color: "#84c9fb"}} className="cursor-pointer" onClick={() => navigator.clipboard.writeText(missingLink)}>[Copy]</button>
                            </div>
                            <div className="col-span-2 lg:col-span-1">
                            Shared missing accessories: 
                            </div>
                            <div>
                                <a href={accessoryLink} target="_blank" rel="noopener noreferrer">[Open]</a> <button style={{color: "#84c9fb"}} className="cursor-pointer" onClick={() => navigator.clipboard.writeText(accessoryLink)}>[Copy]</button>
                            </div>
                        </div>
                    </>
                )}

            </div>

                {!collection.forSale && (
                    <div className="mt-5">
                        <h2>Specify years</h2>
                        <p className="text-gray-400">
                            You can choose to share all years of your collection or specific years. You can choose to differentiate in years between your collection and your missing figures.
                        </p>
                        <div className="flex flex-col lg:flex-row">
                            <div className="w-full lg:w-2/3 grid grid-cols-3 gap-3 mt-5 place-items-center ">
                                <div className="text-sm md:text-base font-bold">
                                    Year
                                </div>
                                <div className="text-sm md:text-base font-bold">
                                    Collection
                                </div>
                                <div className="text-sm md:text-base font-bold">
                                    Missing figures
                                </div>

                            {sharedYears.map((x, index) => (
                                <>
                                    <div className="">
                                        {x.year}
                                    </div>
                                    <div className="">
                                        <input type="checkbox" onChange={collectionYearChange(index)} defaultChecked={x.sharedCollection === true} className="input-base" />
                                    </div>
                                    <div className="">
                                        <input type="checkbox" onChange={missingYearChange(index)} defaultChecked={x.sharedMissing === true} className="input-base" />
                                    </div>
                                </>
                            ))}
                                <div className="col-span-full w-full mt-5 flex flex-col flex-wrap lg:flex-nowrap">
                                    <button onClick={updateSharedYears} className="my-2 lg:my-2 w-full md:w-auto btn-blue mx-0">Update years</button>                    
                                </div>
                            </div>

                        </div>
                    </div>                
                 )}
            

                {/*

                
                <div className="w-full mt-5 flex justify-center flex-wrap">
                    <button onClick={updateSharedYears} className="my-2 md:my-0 w-full md:w-auto btn-blue mx-2 flex-grow">Update years</button>                    
                    <button onClick={cancel} className="my-2 md:my-0 w-full md:w-auto btn-gray mx-2 flex-grow">Return to collection</button>
                </div>
                */}
        </div>
    </>)
}

export default withRouter(HideCollection)