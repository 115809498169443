import { useApolloClient } from '@apollo/react-hooks';
import React from 'react'
import { Route } from 'react-router';
import Layout from '../Components/Common/Layout';
import { useAppContext } from '../context/appcontext';
import { GET_COLLECTIONS_QUERY } from '../graphql/queries';

const MyCollectionRoute = ({ children, ...rest }) => {
    const client = useApolloClient()
    var {dispatch, collection} = useAppContext()

    let isMainCollection = (collection?.forSale === false && collection?.allowDuplicates === false)

    if (!isMainCollection && collection) {
        client.query({query: GET_COLLECTIONS_QUERY}).then(result => {
            let myCollection = result.data.collections.myCollections.filter(x => x.forSale === false && x.allowDuplicates === false)[0]
                dispatch({
                    type: "setCollection",
                    payload: {
                        collection: myCollection,
                        collectionId: myCollection?.id,
                    }
                })
            
            
    })}

    return (
        <Layout>
          <Route
            {...rest}
    //        render = {() => children}
          
          />
        </Layout>

    );
  }
  
export default MyCollectionRoute