export const theme = {
    //backgroundColor: "#343a40"
    //backgroundColor: "#18242b",
    backgroundColor: "#212121",
    textColor: "#FAFAFA",
    captionColor: "#7487a8",
    borderColor: "#84c9fb",
    primaryHover: "#84c9fb",
    linkColor: "#84c9fb",
    someColor: "#d87b7b",
    someOtherColor: "#7487a8",
    mobile: '991px',


  };
  