import { useQuery } from '@apollo/react-hooks'
import React from 'react'
import CATALOGFIGURE_QUERY from '../../graphql/catalogfigurequery'
import ErrorComponent from '../ErrorComponent'
import SpinnerComponent from '../SpinnerComponent'
import FigureSearchComponent from './FigureSearchComponent'

const FigureSearch = ({onChange, defaultValue}) => {
    const {data, loading, error} = useQuery(CATALOGFIGURE_QUERY)

    if (loading) return <SpinnerComponent LoadingText="Loading figures..."/>;
    if (error) return <ErrorComponent />

    

    return (
        <>
            <FigureSearchComponent 
                figures={data.catalogFigures} 
                onChange={onChange}
                defaultValue={defaultValue}
            />
        </>
    )

}

export default FigureSearch