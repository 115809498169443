import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import OWNEDFIGURE_QUERY from '../graphql/ownedfigurequery'
import SpinnerComponent from '../Components/SpinnerComponent'
import { withRouter } from "react-router";
import ListMyCollection from '../Components/MyCollection/ListMyCollection';
import ErrorComponent from '../Components/ErrorComponent'
import ScrollToTop from '../Components/ScrollToTop';
import { useAppContext } from '../context/appcontext';
import CATALOGFIGURE_QUERY from '../graphql/catalogfigurequery';

const ForSalePage = (props) => {
    const {collection} = useAppContext()

    const isLoggedIn = localStorage.getItem("token") !== null

    if (!isLoggedIn) {
        props.history.push("/login")
    }

    const { loading: catalogLoading, error: catalogError, data: catalogData } = useQuery(CATALOGFIGURE_QUERY);
    const { loading: ownedFigureLoading, error: ownedFigureError, data: ownedFigureData } = useQuery(OWNEDFIGURE_QUERY, 
        {
            variables: {
                collectionId: collection?.id
            }
        });

    if (catalogLoading || ownedFigureLoading) return <SpinnerComponent LoadingText="Loading your collection..."/>;
    if (catalogError || ownedFigureError) return <ErrorComponent />;

    

    return (<>
        <ScrollToTop />
        <ListMyCollection allFigures={catalogData.catalogFigures} forSale={true} hasForSaleCollection={collection.forSale} allowDuplicates={true} data={ownedFigureData} />
    </>)
}

export default withRouter(ForSalePage)
