import React, {useState} from 'react'
import {Link} from 'react-router-dom'

import Burger from '../Menu/Burger'
import Menu from '../Menu/Menu'
import { useLocation } from 'react-router-dom'
import ProfileMenu from '../User/ProfileMenu'






export const Navigation = () => {
    const [open, setOpen] = useState(false)
    let location = useLocation();
    return (
        <>
                <nav className="py-6 font-semibold uppercase z-50 flex-row flex-nowrap justify-center items-center bg-gray-700 px-4">
                    <section className="p-4 block pb-0">
                    <div className="container">

                    <div className="flex justify-items-start items-center w-full">
                        <div className="">
                            <a className="font-montserrat font-bold text-3xl inline-block mr-4 text-white flex-grow" href={process.env.REACT_APP_WWW_URL}>GIJOE.IO</a>
                        </div>
                        <div className="lg:flex px-4 invisible hidden lg:visible flex-grow lg:text-xs xl:text-base">
                            <ul className="font-montserrat font-bold mt-0 flex flex-row ml-auto pl-0 mb-0 list-none uppercase flex-grow ">
                                
                                <li className="pt-2 mx-1">
                                    <Link className={`text-white p-4 rounded-lg block no-underline cursor-pointer list-none uppercase hover:no-underline ${location.pathname.startsWith("/my-collection") ? "bg-gray-400" : null}`} to="/my-collection/">My collection</Link>
                                </li>                          
                                <li className="pt-2 mx-1">
                                    <Link className={`text-white p-4 rounded-lg block no-underline cursor-pointer list-none uppercase hover:no-underline ${location.pathname.startsWith("/missing/") ? "bg-gray-400" : null}`} to="/missing/">Missing figures</Link>
                                </li>                            

                                <li className="pt-2 mx-1">
                                    <Link className={`text-white p-4 rounded-lg block no-underline cursor-pointer list-none uppercase hover:no-underline ${location.pathname.startsWith("/missing-accessories") ? "bg-gray-400" : null}`} to="/missing-accessories/">Missing accessory</Link>
                                </li>                            

                                <li className="pt-2 mx-1">
                                    <Link className={`text-white p-4 rounded-lg block no-underline cursor-pointer list-none uppercase hover:no-underline ${location.pathname.startsWith("/for-sale") ? "bg-gray-400" : null}`} to="/for-sale/">For sale</Link>
                                </li>        
                                <li className="pt-2 mx-1">
                                    <Link className={`text-white p-4 rounded-lg block no-underline cursor-pointer list-none uppercase hover:no-underline ${location.pathname.startsWith("/filecards") ? "bg-gray-400" : null}`} to="/filecards/">Filecards</Link>
                                </li>                            

                            </ul>
                        </div>
                        <ProfileMenu />

                        <Burger open={open} setOpen={setOpen} />
                        <Menu open={open} setOpen={setOpen} />           

                        </div>
                    
                    </div>
                    </section>

                </nav>
    
        </>
    )
}

export default Navigation