import styled from 'styled-components'

export const CheckBoxGroup = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;   
    padding: 10px; 
    
    @media (max-width: 991px) {
        font-size: 12px;        
    }    

`

export const CheckBoxContainer = styled.div`
    margin: 20px;

    @media (max-width: 991px) {
        margin: 10px;
    }        

`

export const FigureCard = styled.div`
    position: relative;
    display: block;
    max-width: 20rem;
    border-radius: 0.3rem;
    overflow: hidden;    
    margin-bottom: 5px;
`

export const MissingFigureCard = styled(FigureCard)`

`

export const PlussSign = styled.div`
        z-index: 1000;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        color: #7487a8;
        padding: 6px 6px;
        font-size: 200px;
        font-weight: bold;
        border-radius: 10px;
        transition: all .5s ease;
`

export const FigureCaption = styled.div`
    margin-left: auto !important;
    margin-right: auto !important;

    background-color: ${props => props.theme.backgroundColor    };
    color: white;

    @media (max-width: 768px) {
        max-width: 20rem;
    }    

    
    @media (max-width: 991px) {
        max-width: 20rem;
    }    
`

export const FigureOverlay = styled.div`
    position: absolute;
    color: #f1f1f1;
    width: auto;
    transition: .5s ease;
    opacity: 1;
    color: white;
    font-weight: normal;
    padding: 10px;
    text-align: center;

    ${props => {if (props.type === "complete") return (`
        font-size: 16px;       
        color: white;
        bottom: 0;
        background: #8bc34a;
        opacity: 0.9;
        width: 100%;
    `)}}

    ${props => {if (props.type === "incomplete") return (`
        bottom: 0;
        background: rgba(230, 0, 0, 0.8);
        width: 100%;
        font-size: 16px;       
    `)}}
        
    

    ${props => {if (props.type === "add") return (`
        right: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.9);
        font-size: 12px;
    `)}}

    ${props => {if (props.type === "tag") return (`
        left: 0;
        bottom: 50px;
        background: rgba(199, 206, 158, 1);
        font-size: 12px;
    `)}}


    a {
        color: black;
        text-decoration: none;
    }
`

export const FigureContainer = styled.div`
    margin-bottom: 25px;
`

export const FigureRow = styled.div`
    margin-bottom: 5px;
`