import React from 'react'


const SpinnerComponent = (props) => {
    return (
        <div className="top-0 left-0 right-0 bottom-0 w-full h-screen z-50 opacity-75 flex flex-col items-center justify-center">
            <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-white-900">
            </div>
            {props.LoadingText !== ""
        ? (
                <div className="mt-5">
                    <p>{props.LoadingText}</p>
                </div>
        )
        : ""}

        </div>
    )
}

export default SpinnerComponent