import React from 'react'
import Footer from '../Footer'
import NavigationPublic from './NavigationPublic'

const LayoutPublic = ({children}) => {

    return (
        <>
            <NavigationPublic />

            <section className="mt-2 lg:mt-8 block pb-0 px-4">
                {children}
            </section>
            <Footer />
        </>
    )
}

export default LayoutPublic