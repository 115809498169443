import React from 'react'
import SpinnerComponent from '../Components/SpinnerComponent';
import HideCollection from '../Components/TogglePublicCollection/HideCollection';
import MakeCollectionPublic from '../Components/TogglePublicCollection/MakeCollectionPublic';
import { useAppContext } from '../context/appcontext';

const TogglePublicCollectionPage = ({history}) => {
    const isLoggedIn = localStorage.getItem("token") !== null

    if (!isLoggedIn) {
        history.push("/login")
    }

    const {collection, user} = useAppContext();


    if (!user || !collection) return <SpinnerComponent />
    

    return !collection.isPublic
        ? <MakeCollectionPublic collection={collection} user={user}/>
        : <HideCollection collection={collection} />
        
    


}

export default TogglePublicCollectionPage